/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "pages/abstractPage",
  "wizard/uiGenerator",
  "wizard/ruleManager",
  "templates/computeResourceDetailPage",
  "templates/downloadRDPTemplate",
  "templates/computeResourceDetailCodeGenTemplate",
  "computeResource/computeValueFormatter",
  "dialogs/copyToClipboardDialog",
  "dialogs/connectCredentialsDialog",
  "service/cachedDataService",
  "service/cloudStatus",
  "service/cloudResource",
  "service/editSource",
  "service/landingPageNavButtons",
  "service/datatransform/dependentOptionInfo",
  "service/datatransform/credentialInfo",
  "service/cloudAccessManager",
  "service/cloudOutputsManager",
  "util",
  "pages/forms/editConfigForm",
  "wizard/dynamic/dependentOptionDataBuilder",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "mw-progress-indicator/ProgressIndicator",
  "statusAndCountdown",
  "timerDropdown",
  "supportedProducts",
  "computeResource/collections/collectionFactory",
  "computeResource/collections/collectionItemFactory",
  "service/datatransform/ruleInfo"
], function (_, $, AbstractPage, UIGenerator, RuleManager, PageTemplate,
  DownloadRDPTemplate, ComputeResourceDetailCodeGenTemplate,
  ComputeValueFormatter, CopyToClipboardDialog,
  ConnectCredentialsDialog, CachedDataService, CloudStatus,
  CloudResource, EditSource,
  LandingPageNavButtons, DependentOptionInfo, CredentialInfo,
  CloudAccessManager, CloudOutputsManager,
  Util, EditConfigForm, DependentOptionDataBuilder,
  I18NStringResource, DojoString, ProgressIndicator,
  StatusAndCountdown, TimerDropdown, SupportedProducts,
  CollectionFactory, CollectionItemFactory, RuleInfo) {

  const ACTION_BUTTONS = ["clone", "start", "stop", "resume", "pause", "rdp",
    "connect", "edit", "archive", "hard-delete"];
  /**
   * This subclass of MatlabDriveApplication uses
   * Mathworks FileBrowser2 widget for the tree controller.
   */
  class ComputeResourceDetailsPage extends AbstractPage {

    constructor(params) {
      super(params);
      this.configId = null;
      this.pageTemplate = PageTemplate;
      this.downloadRDPTemplate = DownloadRDPTemplate;
      this.uiGenerator = null;
      this.currentEditConfigSettings = null;
      this.configChanged = false;
      this.mwName = "";
      this.cloudResource = null;
      this.ip = "";
      this.ipIsPublic = false;
      this.editOrigin = EditSource.Enum.DETAILS_PAGE;
      if (Util.clientCachingIsEnabled()) {
        this.localDataService = new CachedDataService({
          baseService: this.getDataService()
        });
        this.initLocalDataService();
      } else {
        this.localDataService = this.getDataService();
      }
      this.statusAndCountdown = null;
      this.timerDropdown = null;
      this.resourceType = null;
      this.events = {
        "click div#computeResourceDetailPageView > div.facetBar > ul.facets > li > a#resourceLink": "goToResource",
        "click div#computeResourceDetailPageView > div.facetBar > ul.facets > li > a#credentialLink": "goToCredential",
        "click div#computeResourceDetailPageView > div.computeDetailsContainer > div.actionIconContainer > button.action_icon.edit": "edit",
        "click div#computeResourceDetailPageView > div.computeDetailsContainer > div.actionIconContainer > button.action_icon.start": "start",
        "click div#computeResourceDetailPageView > div.computeDetailsContainer > div.actionIconContainer > button.action_icon.stop": "warnStop",
        "click div#computeResourceDetailPageView > div.computeDetailsContainer > div.actionIconContainer > button.action_icon.resume": "resume",
        "click div#computeResourceDetailPageView > div.computeDetailsContainer > div.actionIconContainer > button.action_icon.pause": "warnPause",
        "click div.computeDetailsContainer div.IPContainer > button#copyIPValueButton": "copyIP",
        "click div.generatedCode > div.accessList > div.outputContainer > dl.dl-horizontal > dd > button.copyOutputValueButton": "copyOutput",
        "click div.generatedCode > div.outputsWrapper button.copyOutputValueButton": "copyOutput",
        "dragover div.computeDetailsContainer": "dragoverNotAllowed",
        "click div#computeResourceDetailPageView button.viewPassword": "toggleViewPassword",
        "click div#computeResourceDetailPageView a.sectionExpander": "toggleSection",
        "click button.btn.btn-link.default-link.ComputeResourceDetailsPage": "goToHome",
        "click div#computeResourceDetailPageView dl.row.mainDetailTableList button.warning-notification": "onWarningNotificationButtonClick"
      };
      // bind 'this' in the listed methods to the current 'this',
      // even when called by other modules.
      _.bindAll(this, "logoutPage", "getAuthManager", "goToResource",
        "goToCredential", "start", "stop", "warnStop", "resume",
        "pause", "warnPause", "edit", "copyIP", "toggleViewPassword",
        "goToHome");
    }

    /*
      Caching
    */
    getLocalDataService() { return this.localDataService; }
    initLocalDataService() {
      this.getLocalDataService().init(
        [
          "workflow",
          "ui",
          "logging",
          "user"
        ], // components to add to localDataService
        // APIs to cache
        [
          {
            set: "ui/getConfigDetailData", // load cache api
            clear: [ // clear cache api
              "workflow/update",
              "workflow/start",
              "workflow/resume",
              "workflow/pause",
              "workflow/stop"
            ]
          },
          {
            set: "ui/queryDependentOptionData",
            clear: null,
          },
          {
            set: "ui/getRulesArrayByProduct",
            clear: null
          },
          {
            set: "workflow/list",
            clear: null
          },
          {
            set: "workflow/cloudAccess",
            clear: [
              "workflow/addCloudAccess",
              "workflow/removeCloudAccess"
            ]
          },
          {
            set: "workflow/outputs",
            clear: null
          },
        ]
      );
    }

    /*
      Getters/Setters
    */

    getUIGenerator() {
      return this.uiGenerator;
    }
    setUIGenerator(generator) {
      this.uiGenerator = generator;
    }

    getCurrentEditConfigSettings() {
      return this.currentEditConfigSettings;
    }
    setCurrentEditConfigSettings(settings) {
      this.currentEditConfigSettings = settings;
    }

    getCloudResource() { return this.cloudResource; }
    setCloudResource(cloudResource) { this.cloudResource = cloudResource; }

    getGeneratedCodeContainer() { return this.el.querySelector('div.computeDetailsContainer > div.generatedCode'); }
    getEditFormContainer() { return this.el.querySelector('div#computeResourceConfigEditContainer'); }
    getAdditionalDetailGeneratedCodeContainer() { return this.el.querySelector("div.additionalDetailContainer > div.generatedCode"); }
    getShortAddtionalDetailContainer() { return this.el.querySelector("div.additionalDetailContainer > div.shortAddtionalDetail > p"); }

    setInitialStartupCheck(detailData) {
      if (detailData) {
        //This is "template_only", "all_machines", etc. to let the system know what we're waiting for
        this.initialStartupCheck = detailData.initial_startup_check;
      }
    }
    getInitialStartupCheck() {
      return this.initialStartupCheck;
    }

    setSupportFirewallModification(detailData) {
      if (detailData) {
        this.supportFirewallModification = detailData.support_firewall_modification !== "false";  //support_firewall_modification is a string
      }
    }
    getSupportFirewallModification() {
      return this.supportFirewallModification;
    }

    setConfigId(configId) { this.configId = configId; }
    getConfigId() { return this.configId; }

    setResourceType(product) { this.resourceType = product; }
    getResourceType() { return this.resourceType; }

    getIP() { return this.ip; }
    setIP(ip, isPublic = false) {
      this.ip = ip;
      this.setIPisPublic(isPublic);
      this.renderIP();
      this.renderProviderResourceStatus();
    }
    resetIP() {
      this.ip = undefined;
      this.ipIsPublic = false;
    }
    isPublicIP() { return this.ipIsPublic; }
    setIPisPublic(isPublic) { this.ipIsPublic = isPublic; }

    getName() { return this.mwName; }
    setName(detailData) {
      if (typeof detailData === 'object') {
        this.mwName = detailData["mw-name"];
      } else if (typeof detailData === 'string') {
        this.mwName = detailData;
      } else {
        throw new TypeError("Invalid detailData argument");
      }
    }

    getAccessGroupId() { return this.accessGroupId; }
    setAccessGroupId(detailData) {
      let accessGroupId = "";
      if ((detailData && typeof detailData === 'object') &&
        (detailData.accessData && typeof detailData.accessData === 'object' && detailData.accessData.length) &&
        (detailData.accessData[0] && detailData.accessData[0].id && typeof detailData.accessData[0].id === 'string')) {
        accessGroupId = detailData.accessData[0].id;
      }
      this.accessGroupId = accessGroupId;
    }

    getOperatingSystem() { return this.operating_system; }
    setOperatingSystem(detailData) {
      let os = "linux";
      if ((detailData && typeof detailData === 'object') &&
        (detailData.operating_system && typeof detailData.operating_system === 'string')) {
        os = detailData.operating_system;
      }
      this.operating_system = os;
    }
    getAccessType() { return this.accessType; }
    setAccessType(detailData) {
      let accessProtocolParam = this.getAccessProtocolParam(detailData);
      let accessType = "";
      if ((detailData && typeof detailData === 'object') &&
        (detailData[accessProtocolParam] && typeof detailData[accessProtocolParam] === 'string')) {
        accessType = detailData[accessProtocolParam];
      }
      this.accessType = accessType;
    }

    getAccessProtocolParam(detailData) {
      if (!this.accessProtocolParam && detailData && typeof detailData === 'object') {
        this.accessProtocolParam = "AccessProtocol";
        if ("accessProtocol" in detailData) {
          this.accessProtocolParam = "accessProtocol";
        }
      }
      return this.accessProtocolParam;
    }

    getUsername() { return this.username; }
    setUsername(detailData) {
      let username = "ubuntu";
      if ((detailData && typeof detailData === 'object') &&
        (detailData.Username && typeof detailData.Username === 'string')) {
        username = detailData.Username;
      } else if ((detailData && typeof detailData === 'object') &&
        detailData["mw-username"] && typeof detailData["mw-username"] === 'string') {
        username = detailData["mw-username"];
      }
      this.username = username;
    }

    getPassword() { return this.password; }
    setPassword(detailData) {
      let password = "none";
      if ((detailData && typeof detailData === 'object') &&
        (detailData.Password && typeof detailData.Password === 'string')) {
        password = detailData.Password;
      }
      this.password = password;
    }

    getEditOrigin() { return this.editOrigin; }
    setEditOrigin(origin) { this.editOrigin = origin; }

    setStatusAndCountdown(statusAndCountdown) { this.statusAndCountdown = statusAndCountdown; }
    getStatusAndCountdown() { return this.statusAndCountdown; }
    setTimerDropdown(timerDropdown) { this.timerDropdown = timerDropdown; }
    getTimerDropdown() { return this.timerDropdown; }

    /*
      Action buttons
    */
    copyIP(event) {
      this.processEvent(event);
      let ipText = this.ip;
      let copyToClipboardDialog = new CopyToClipboardDialog();
      copyToClipboardDialog.copyToClipboard(ipText);
    }

    /* istanbul ignore next */
    copyOutput(event) {
      this.processEvent(event);
      let urlText = event.currentTarget.dataset.url;
      let copyToClipboardDialog = new CopyToClipboardDialog();
      copyToClipboardDialog.copyToClipboard(urlText);
    }

    getActionButtonSelector(buttonClassname) {
      if (!buttonClassname || typeof buttonClassname !== 'string') {
        throw new TypeError("Invalid buttonClassname argument");
      }
      return `div.actionIconContainer > button.action_icon.${buttonClassname}`;
    }

    hideActionButtons() {
      for (let button of ACTION_BUTTONS) {
        if (button === "clone") { this.enableActionButton(button); }
        let buttonElement = this.el.querySelector(this.getActionButtonSelector(button));
        if (buttonElement) {
          if (button === "connect" || button === "clone" || button === "rdp" || button === "edit") {
            buttonElement.style.display = "inline-block";
            this.disableActionButton(button);
          } else {
            buttonElement.style.display = "none";
          }
        }
      }
    }

    updateActionButtons(status) {
      if (!status) {
        status = CloudStatus.Enum.UNKNOWN;
      }
      let bte = CloudStatus.getMenuItems(status);
      let buttonsToEnable = [];
      if (bte) {
        for (let b of bte) {
          if (status === CloudStatus.Enum.STARTED ||
            status === CloudStatus.Enum.STARTING ||
            status === CloudStatus.Enum.TERMINATING ||
            status === CloudStatus.Enum.PAUSING) {
            if (["start", "stop", "resume", "pause"].indexOf(b) >= 0) {
              continue;
            }
          }
          buttonsToEnable.push(b);
        }
      }
      this.hideActionButtons();
      if (buttonsToEnable) {
        for (let button of buttonsToEnable) {
          let buttonElement = this.el.querySelector(this.getActionButtonSelector(button));
          if (buttonElement) {
            buttonElement.style.display = "inline-block";
            this.enableActionButton(button);
          }
        }
      }
    }

    disableActionButton(buttonClassname) {
      let button = this.el.querySelector(this.getActionButtonSelector(buttonClassname));
      if (button) {
        button.disabled = true;
        button.classList.add("disabled");
      }
    }

    enableActionButton(buttonClassname) {
      let button = this.el.querySelector(this.getActionButtonSelector(buttonClassname));
      if (button) {
        button.disabled = false;
        button.classList.remove("disabled");
      }
    }

    /*
      Data Getters: Queries
    */
    async getDetailData(configId, useCache = true) {
      const collection = CollectionFactory.getProductCollection(this.getResourceType());
      await collection.initializeCache(0, false);
      return collection.getCollectionItemDetails(configId, useCache);
    }

    async getStatus(configId, useCache = true) {
      if (!configId || typeof configId !== 'string') {
        throw new TypeError("Invalid configId argument");
      }
      let data;
      let status;
      let statusTransitionPercent;
      let machineData;
      try {
        this.resetIP();
        data = await this.getDetailData(configId, useCache);
        // Save IP
        if (data.allMachinesData && Array.isArray(data.allMachinesData) && data.allMachinesData.length) {
          machineData = data.allMachinesData[0];
          if (machineData.public_ip) {
            this.setIP(machineData.public_ip, true);
          } else {
            this.setIP(machineData.private_ip, false);
          }
        }
        status = CloudStatus.getStatus(data.cloudData);

        if (data.cloudData && data.cloudData.state_percent && !isNaN(parseFloat(data.cloudData.state_percent))) {
          statusTransitionPercent = (parseFloat(data.cloudData.state_percent) * 100);
        }
      } catch (error) {
        Util.consoleLogError("getStatus", error);
        status = CloudStatus.Enum.UNKNOWN;
      }
      let statusData = {
        status: status,
        percent: statusTransitionPercent
      }
      return statusData;
    }

    /*
      Cloud Resource Controls (E.g., start machine, stop machine, etc.)
    */
    async start(event) {
      this.processEvent(event);
      let configId = this.getConfigId();
      try {
        this.disableActionButton("start");
        this.updateActionButtons(CloudStatus.Enum.STARTING);
        let data = await this.getLocalDataService().workflow.start(configId, this.getResourceType());
        Util.notify("INFO", DojoString.substitute(I18NStringResource.computeResourceDetailPageStartSuccess, [this.getName()]));
      } catch (error) {
        let errDetails;
        try {
          errDetails = JSON.parse(error.message);
          Util.consoleLogError("start", errDetails.stack[0].details);
          Util.notify("ERROR", `${this.getName()}: ${DojoString.substitute(I18NStringResource.dataServiceErrorServerErrorWithInfo, [errDetails.stack[0].details])}`);
        } catch (parseError) {
          Util.consoleLogWarning('start', parseError.message);
          if (error.message) {
            Util.notify("ERROR", `${this.getName()}: ${DojoString.substitute(I18NStringResource.dataServiceErrorServerErrorWithInfo, [error.message])}`);
          }
        }
      } finally {
        await this.refreshUI(1, true, true);
      }
    }

    warnStop(event) {
      this.processEvent(event);
      LandingPageNavButtons.showWarningDialog(this.stop.bind(this), this.getInitialStartupCheck(), "stop");
    }

    async stop(event) {
      this.processEvent(event);
      let configId = this.getConfigId();
      try {
        this.disableActionButton("stop");
        this.updateActionButtons(CloudStatus.Enum.TERMINATING);
        let data = await this.getLocalDataService().workflow.stop(configId, this.getResourceType());
        Util.notify("INFO", DojoString.substitute(I18NStringResource.computeResourceDetailPageStopSuccess, [this.getName()]));
      } catch (error) {
        Util.consoleLogError("stop", error);
        Util.notify("ERROR", `${this.getName()}: ${DojoString.substitute(I18NStringResource.dataServiceErrorServerErrorWithInfo, ["Stop failed"])}`);
      } finally {
        this.enableActionButton("stop");
        await this.refreshUI(1, true, true);
      }
    }

    async resume(event) {
      this.processEvent(event);
      let configId = this.getConfigId();
      try {
        this.disableActionButton("resume");
        this.updateActionButtons(CloudStatus.Enum.RESUMING);
        let data = await this.getLocalDataService().workflow.resume(configId, this.getResourceType());
        Util.notify("INFO", `${this.getName()}: ${I18NStringResource.computeResourceDetailPageStartSuccess}`);
      } catch (error) {
        Util.consoleLogError("resume", error);
        Util.notify("ERROR", `${this.getName()}: ${DojoString.substitute(I18NStringResource.dataServiceErrorServerErrorWithInfo, ["Resume failed"])}`);
      } finally {
        await this.refreshUI(1, true, true);
      }
    }

    warnPause(event) {
      this.processEvent(event);
      LandingPageNavButtons.showWarningDialog(this.pause.bind(this), "pause", this.getInitialStartupCheck());
    }

    async pause(event) {
      this.processEvent(event);
      let configId = this.getConfigId();
      try {
        this.disableActionButton("pause");
        this.updateActionButtons(CloudStatus.Enum.PAUSING);
        let data = await this.getLocalDataService().workflow.pause(configId, this.getResourceType());
        Util.notify("INFO", DojoString.substitute(I18NStringResource.computeResourceDetailPagePauseSuccess, [this.getName()]));
      } catch (error) {
        Util.consoleLogError("pause", error);
        Util.notify("ERROR", `${this.getName()}: ${DojoString.substitute(I18NStringResource.dataServiceErrorServerErrorWithInfo, ["Pause failed"])}`);
      } finally {
        await this.refreshUI(1, true, true);
      }
    }

    /*
      Page-specific (Navigation start/stop page listeners, rendering, etc.)
    */
    goToResourceDetail(event) { /* do nothing */ }

    startPage() {
      super.startPage();
    }

    stopPage() {
      super.stopPage();
      CloudResource.stopAll();
    }

    async render() {
      if (!this.urlArgs || !this.urlArgs.path) {
        this.goToResource();
        return;
      }
      const configId = this.urlArgs.path;
      this.setConfigId(configId);
      if (!this.urlArgs || !this.urlArgs.product) {
        this.setResourceType('matlab');
      } else {
        this.setResourceType(this.urlArgs.product);
      }
      this.initLocalDataService();
      RuleManager.initializeRuleManagerProductMap(this.localDataService);
      let am = this.getAuthManager();

      const userProfile = await this.getLocalDataService().user.profile();
      CollectionFactory.initializeProductCollectionsForUser(userProfile, this.getLocalDataService());
      const detailData = await this.getDetailData(configId, !this.isConfigChanged());
      let { supportsTermPolicy, supportsIdleTermPolicy, termPolicyDropdownValues } = await this.lookupTerminationPolicyDetailsFromRule(detailData);

      const product = (detailData && detailData.product) ? detailData.product : "";
      this.setInitialStartupCheck(detailData);
      this.setSupportFirewallModification(detailData);

      /* istanbul ignore next */
      const touchevents = Util.touchEventsEnabled();
      let templateParameters = {
        headerNavigation: this.renderNavigationHeader(),
        computeLinkText: I18NStringResource.computeLinkText,
        credentialLinkText: I18NStringResource.credentialLinkText,
        releaseLicenseWarningText: I18NStringResource.releaseNeedsLicenseWarning,
        startActionLabel: I18NStringResource.computeResourcePageActionStart,
        startTooltipText: I18NStringResource.computeResourcePageActionStartTooltip,
        stopActionLabel: I18NStringResource.computeResourcePageActionStop,
        stopTooltipText: I18NStringResource.computeResourcePageActionStopTooltip,
        resumeActionLabel: I18NStringResource.computeResourcePageActionResume,
        resumeTooltipText: I18NStringResource.computeResourcePageActionResumeTooltip,
        pauseActionLabel: I18NStringResource.computeResourcePageActionPause,
        pauseTooltipText: I18NStringResource.computeResourcePageActionPauseTooltip,
        editActionLabel: I18NStringResource.computeResourcePageActionEdit,
        editTooltipText: I18NStringResource.computeResourcePageActionEditTooltip,
        terminationPolicyWarning: I18NStringResource.terminationPolicyWarning,
        terminationPolicyResetTimerButton: I18NStringResource.terminationPolicyResetTimerButton,
        terminationPolicyResetTimerButtonTooltip: I18NStringResource.terminationPolicyResetTimerButtonTooltip,
        cloneButtonTooltip: I18NStringResource.cloneButtonTooltip,
        providerResourceStatusLabel: I18NStringResource.providerResourceStatusLabel,
        startButtonTooltip: I18NStringResource.startButtonTooltip,
        resumeButtonTooltip: I18NStringResource.resumeButtonTooltip,
        pauseButtonTooltip: I18NStringResource.pauseButtonTooltip,
        stopButtonTooltip: I18NStringResource.stopButtonTooltip,
        editButtonTooltip: I18NStringResource.editButtonTooltip,
        timeoutPolicyLabel: I18NStringResource.timeoutPolicyLabel,
        displayResourceSpecificNotifications: I18NStringResource.viewNotifications,
        computeResourceDetailPageTitle: I18NStringResource.computeResourceDetailPageTitle,
        product: product
      };
      let htmlResult = this.pageTemplate(templateParameters);
      this.$el.empty().html(htmlResult);

      const statusMarker = this.el.querySelector("div.statusAndCountdownContainer");
      if (statusMarker) {
        this.setStatusAndCountdown(new StatusAndCountdown({
          supportsTermPolicy: supportsTermPolicy
        }));
        this.getStatusAndCountdown().setElement(statusMarker);
        this.getStatusAndCountdown().render();
      }
      const timerDropdownMarker = this.el.querySelector("div.timeoutDropdownContainer");
      if (timerDropdownMarker) {
        this.setTimerDropdown(new TimerDropdown({
          configId: configId,
          supportsTermPolicy: supportsTermPolicy,
          supportsIdleTermPolicy: supportsIdleTermPolicy,
          termPolicyDropdownValues: termPolicyDropdownValues,
          dataService: this.getLocalDataService(),
          statusAndCountdown: this.getStatusAndCountdown(),
          preUpdateMethod: this.preTerminationPolicyUpdate.bind(this),
          postUpdateMethod: this.postTerminationPolicyUpdate.bind(this)
        }));
        this.getTimerDropdown().setElement(timerDropdownMarker);
        this.getTimerDropdown().render();
      }
      if (!touchevents) {
        $('[data-bs-toggle="tooltip"]').tooltip();
      }
      this.renderMatrix();
      if (!this.urlArgs || typeof this.urlArgs !== "object" || Object.keys(this.urlArgs).length === 0 || !this.urlArgs.path) {
        this.goToResource();
        return;
      }
      await this.initializeView(detailData);
    }

    preTerminationPolicyUpdate() {
      this.disableActionButton("clone");
    }

    async postTerminationPolicyUpdate(configId) {
      const detailData = await this.getDetailData(configId, false);
      await this.renderSummaryDetails(detailData);
    }

    async initializeView(detailData) {
      const configId = this.getConfigId();
      const isOtherOriginEdit = (this.urlArgs && this.urlArgs.params && !!this.urlArgs.params.edit);
      this.cloudResource = null;  // reset
      let busySpinner = this.el.querySelector('div#detailsBusySpinnerContainer');
      let detailsContainer = this.el.querySelector('div.computeDetailsContainer');
      this.setName(I18NStringResource.computeResourceDetailPageNameDataUnavailable);
      try {
        await RuleManager.getRuleManagerForProduct(detailData.product).initialize();
        // clear cached data before making call
        this.getLocalDataService().clearAll();
        if (!isOtherOriginEdit) {
          if (busySpinner) {
            busySpinner.style.display = "none";
          }
          if (detailsContainer) {
            detailsContainer.style.display = "block";
          }
        }
        if (detailData) {
          this.setUsername(detailData);
          this.setPassword(detailData);
          this.setOperatingSystem(detailData);
          this.setAccessType(detailData);
          this.setAccessGroupId(detailData);
          this.setName(detailData);
          await this.renderSummaryDetails(detailData);
        }
        await this.updateResourceCountdownTimer(configId, true);
        let status = await this.refreshUI(1, false, false, true);
        if (isOtherOriginEdit) {
          let path = "";
          if (this.urlArgs.product) {
            path += Util.convertProductToUserFriendlyProduct(this.urlArgs.product) + '/';
          }
          path += this.urlArgs.path;
          this.resetHistoryURL("resource-detail", { path: path });
          const enabledButtons = CloudStatus.getMenuItems(status);
          if (enabledButtons.includes("edit")) {
            this.edit(null, { editOrigin: "RESOURCE_PAGE" });
          } else {
            Util.notify("ERROR", `${this.getName()}: ${I18NStringResource.computeResourceDetailPageStatusWrongForEditing}`);
          }
        }
        if (this.urlArgs && this.urlArgs.params && this.urlArgs.params.start) {
          this.resetHistoryURL("resource-detail", { path: this.urlArgs.path });
          this.start();
        }
      } catch (error) {
        Util.consoleLogError("initializeView", error);
        Util.notify("ERROR", DojoString.substitute(I18NStringResource.dataServiceErrorServerErrorWithInfo, [I18NStringResource.failedToGetData]));
      } finally {
        if (!isOtherOriginEdit) {
          if (busySpinner) {
            busySpinner.style.display = "none";
          }
          if (detailsContainer) {
            detailsContainer.style.display = "block";
          }
        }
      }
    }

    async refreshPage(skipRenderSummaryDetails = false) {
      let configId = this.getConfigId();
      let detailData = await this.getDetailData(configId, false);
      this.setName(detailData);
      if (!skipRenderSummaryDetails) {
        this.renderSummaryDetails(detailData);
      }
      return detailData;
    }

    renderProviderResourceStatus() {
      let ipValue = this.getIP();
      let statusValue = I18NStringResource.providerResourceNotAllocated;
      let providerResourceStatusValue = this.el.querySelector('div.computeDetailsContainer > div.providerResourceStatusContainer > span.providerResourceStatusValue');
      if (ipValue) {
        statusValue = I18NStringResource.providerResourceAllocated;
      }
      if (providerResourceStatusValue) {
        providerResourceStatusValue.textContent = statusValue;
      }
    }

    renderIP() {
      let ipValue = this.getIP();
      let copyIPButton = this.el.querySelector('div.computeDetailsContainer div.IPContainer > button#copyIPValueButton');
      let ipContainer = this.el.querySelector('div.computeDetailsContainer div.IPContainer > span.ipValue');
      let publicPrivateContainer = this.el.querySelector('div.computeDetailsContainer div.IPContainer > span.ipLabel');
      if (publicPrivateContainer) {
        let label = I18NStringResource.computeResourceDetailPageIPLabel;
        if (ipValue) {
          if (this.isPublicIP()) {
            label = I18NStringResource.computeResourceDetailPagePublicIPLabel;
          } else {
            label = I18NStringResource.computeResourceDetailPagePrivateIPLabel;
          }
        }
        publicPrivateContainer.textContent = label;
      }
      if (ipContainer) {
        let enableCopyButton = (ipValue ? true : false);
        if (!ipValue) {
          ipValue = I18NStringResource.computeDetailsNoAvailableIP;
          copyIPButton.style.display = 'none';
        } else {
          copyIPButton.style.display = 'inline-block';
        }
        ipContainer.textContent = ipValue;
        if (copyIPButton) {
          if (enableCopyButton) {
            copyIPButton.disabled = false;
            copyIPButton.classList.remove('disabled');
          } else {
            copyIPButton.disabled = true;
            copyIPButton.classList.add('disabled');
          }
        }
      }
    }

    emptyGeneratedCode() {
      try {
        let generatedCodeContainer = this.getGeneratedCodeContainer();
        if (generatedCodeContainer) {
          while (generatedCodeContainer.lastChild) { generatedCodeContainer.lastChild.remove(); }
        }
      } catch (error) {
        Util.consoleLogError("emptyGeneratedCode", error);
      }
    }

    showReleaseLicenseWarning() {
      const warningContainer = this.el.querySelector('div.computeDetailsContainer > div.releaseLicenseWarningContainer');
      if (warningContainer) {
        warningContainer.style.display = 'block';
      } else {
        throw new Error("Unable to locate release license warning container");
      }
    }

    async refreshUI(iteration = 1, autoRefresh = true, refreshAdditionalDetails = false, useCache = false) {
      Util.consoleLogTrace("resourceDetailPage", "refreshUI called with iteration " + iteration);
      const refreshUIInterval = 30000;
      const refreshUIInterationLimit = 100;
      const configId = this.getConfigId();
      if (!configId) { return; }
      this.disableActionButton("refresh");
      let statusData = await this.getStatus(configId, true);
      let status = statusData.status;
      if (CloudStatus.isTransitionStatus(status)) {
        autoRefresh = true;
      }
      let percent = Math.min((iteration * 1) + statusData.percent, 100);
      if (!percent && CloudStatus.isTransitionStatus(status)) {
        percent = 1;
      }
      this.getStatusAndCountdown().renderStatus(status, percent);
      this.renderProviderResourceStatus();
      this.updateActionButtons(status);
      if (autoRefresh) {
        ++iteration;
        let detailData = await this.getDetailData(configId, useCache);
        if (iteration === 1) {
          this.checkAndDisplayErrors(detailData);
        }
        // Disable "Apply Timeout" button when it's not running
        let container = this.el.querySelector("div.statusAndCountdownContainer div.resource-countdown");
        if (status !== CloudStatus.Enum.RUNNING) {
          this.getTimerDropdown().hideItems();
          await this.updateResourceCountdownTimer(configId, true);
          if (container) {
            container.style.display = "none";
          }
        } else {
          this.getTimerDropdown().showItems();
          if (container) {
            container.style.display = "block";
          }
          await this.updateResourceCountdownTimer(configId, true);
        }
        // keep re-running while in non-terminal state
        const originalExpectedContainerElement = document.querySelector('div#computeDetailsContainer.computeDetailsContainer');
        if (!(status === CloudStatus.Enum.PAUSED || status === CloudStatus.Enum.TERMINATED || status === CloudStatus.Enum.RUNNING) && iteration < refreshUIInterationLimit) {
          if (status === CloudStatus.Enum.UNKNOWN) { // If status is unknown, the resource could already be deleted, redirect to list page.
            this.goToResource();
            return;
          }
          setTimeout(function () {
            let expectedContainer = document.querySelector('div#computeDetailsContainer.computeDetailsContainer');
            if (expectedContainer && expectedContainer === originalExpectedContainerElement) {
              this.refreshUI(iteration, true, refreshAdditionalDetails);
            }
          }.bind(this), refreshUIInterval);
        } else {
          setTimeout(function () {
            let expectedContainer = document.querySelector('div#computeDetailsContainer.computeDetailsContainer');
            if (expectedContainer && expectedContainer === originalExpectedContainerElement) {
              // do a complete refresh (to update machine and access info)
              Util.closeInfoNotification();
              this.refreshPage(!refreshAdditionalDetails);
            }
          }.bind(this), 0);
        }
      } else {
        Util.closeInfoNotification();
      }
      return status;
    }

    generateDetailListItem(formatterId, list, label, value, tag = null) {
      let itemElement;
      if (list && label && typeof label === 'string') {
        if (formatterId !== "mlName" && value && value.length && value.toLowerCase() === "none") {
          // IDL controls the value of all Step 1 items, except name
          // Per convention, if the value is "none", do not show that row (static fields on details w/o event handling)
          // But since IDL does not control mlName, do not apply this logic to mlName
          return;
        }
        let itemLabel = document.createElement('dt');
        itemLabel.classList.add("col-sm-3");
        itemLabel.textContent = label;
        list.appendChild(itemLabel);
        let itemValue = document.createElement('dd');
        itemValue.classList.add("col-sm-9");
        itemValue.textContent = value;
        if (tag) {
          itemValue.appendChild(tag);
        }
        list.appendChild(itemValue);
      }
    }

    emptyDetailList(list) {
      if (list && list.lastChild) {
        while (list.lastChild) {
          list.lastChild.remove();
        }
      }
    }

    toggleSection(event) {
      let sectionHeader = null;
      if (event) {
        if (event.preventDefault) {
          event.preventDefault();
        }
        if (event.currentTarget) {
          Util.logDDUXinfoFromClickEvent(event, this.getLocalDataService().logging);
        }
        if (event.target) {
          sectionHeader = event.target.closest('.additionalDetailHeader')
        }
      }
      Util.toggleSection(sectionHeader);
    }

    getFieldValue(detailData, fieldName) {
      let value;
      if (!detailData || typeof detailData !== 'object') {
        throw new TypeError("Invalid detailData argument");
      }
      if (!fieldName || typeof fieldName !== 'string') {
        throw new TypeError("Invalid fieldName argument");
      }
      if (fieldName.indexOf('.') >= 0) {
        const re = /^\w+(\.\w+)*$/;
        if (!re.test(fieldName)) {
          throw new TypeError(`Invalid fieldName argument`);
        }
        const pieces = fieldName.split('.');
        const firstPiece = pieces.shift();
        const remainingFieldName = pieces.join('.');
        const newData = detailData[firstPiece];
        value = this.getFieldValue(newData, remainingFieldName);
      } else {
        value = detailData[fieldName];
      }
      return value;
    }

    onWarningNotificationButtonClick(event) {
      return Util.onWarningNotificationButtonClick(event);
    }

    /*
        Generates the equivalent of create Wizard's Step 1
    */
    populateConfigurationSummarySection(detailData) {
      if (!detailData || typeof detailData !== 'object') {
        throw new TypeError("Invalid detailData argument");
      }
      const product = detailData.product;
      let step1Fields;
      try {
        step1Fields = CollectionItemFactory.getWizardStep1Fields(product);
      } catch (error) {
        Util.consoleLogWarning('populateConfigurationSummarySection', error);
      }
      let mainDetailList = this.el.querySelector('dl.mainDetailTableList');
      if (mainDetailList && step1Fields) {
        let formatter = new ComputeValueFormatter();
        this.emptyDetailList(mainDetailList);
        for (let field of step1Fields) {
          const fieldName = field.name;
          const formatterId = field.formatterId;
          let fieldValue = this.getFieldValue(detailData, fieldName);
          const fieldLabel = `cmlWizardSummaryAttributeLabel_${formatterId}`;
          let warningButton = null;

          if (fieldName === "version") {
            const eol = detailData.eol;
            let isDeprecated = false;
            let isSunsetted = false;
            if (eol) {
              if (eol.Status === RuleInfo._EOLStatusValues["DEPRECATED"]) {
                isDeprecated = true;
              }
              if (eol.Status === RuleInfo._EOLStatusValues["SUNSET"]) {
                isSunsetted = true;
              }
            }
            if (isDeprecated || isSunsetted) {
              warningButton = document.createElement('button');
              warningButton.type = "button";
              warningButton.classList.add("warning-notification");
              const tag = document.createElement('span');
              tag.classList.add("icon-alert-warning");
              if (isDeprecated) {
                warningButton.classList.add("deprecated");
                let sunsetDate = Util.formatDateYMD(new Date(eol.SunsetOnDate.seconds * 1000));
                sunsetDate = Util.extractMonthYearString(sunsetDate);
                tag.title = DojoString.substitute(I18NStringResource.deprecatedTooltip, [sunsetDate]);
                warningButton.dataset.status = "Deprecated";
                warningButton.dataset.release = detailData.version;
                warningButton.dataset.deprecatedate = Util.formatDateYMD(new Date(eol.DeprecateOnDate.seconds * 1000));
                warningButton.dataset.sunsetdate = Util.formatDateYMD(new Date(eol.SunsetOnDate.seconds * 1000));
              }
              if (isSunsetted) {
                warningButton.classList.add("sunsetted");
                let sunsetDate = Util.formatDateYMD(new Date(eol.SunsetOnDate.seconds * 1000));
                sunsetDate = Util.extractMonthYearString(sunsetDate);
                tag.title = DojoString.substitute(I18NStringResource.sunsettedTooltip, [sunsetDate]);
                warningButton.dataset.status = "Sunsetted";
                warningButton.dataset.release = detailData.version;
                warningButton.dataset.deprecatedate = Util.formatDateYMD(new Date(eol.DeprecateOnDate.seconds * 1000));
                warningButton.dataset.sunsetdate = Util.formatDateYMD(new Date(eol.SunsetOnDate.seconds * 1000));
              }
              warningButton.appendChild(tag);
            }
            /* istanbul ignore next */
            if (!RuleManager.isEntitledToUseRelease(fieldValue)) {
              fieldValue += " " + I18NStringResource.notEntitled;
              this.showReleaseLicenseWarning();
            }
          }

          if (fieldName === "cloudData.account") {
            if (!fieldValue) {
              fieldValue = I18NStringResource.unknownValue;
            }
          }

          if (fieldValue) {
            this.generateDetailListItem(
              formatterId,
              mainDetailList,
              I18NStringResource[fieldLabel],
              formatter.getDisplayValue(formatterId, fieldValue),
              warningButton
            );
          }
        }
      }
    }

    getCredentialTypeId(configDetailData) {
      let result = "";

      try {
        const platform = configDetailData.cloud_provider;
        const credentialType = configDetailData.credential_type;
        const credentialTypeId = Object.keys(credentialType)[0];
        const accountId = configDetailData['mw-credential-id'];
        const subscriptionId = configDetailData['mw-subscription-id'];
        const credentialInfo = new CredentialInfo();

        credentialInfo.cloudPlatform = platform;
        credentialInfo.subscriptionId = subscriptionId;
        credentialInfo.crendentialTypeId = credentialTypeId;
        credentialInfo.credentialId = accountId;
        credentialInfo.displayName = "No display name set";

        const optionValue = credentialInfo.optionValue;
        const pieces = optionValue.split(':');
        result = pieces[2];
      } catch (error) {
        Util.consoleLogError(`getCredentialTypeId`, error);
        result = "";
      }

      return result;
    }

    /*
        Generates the equivalent of create Wizard's Step 2
    */
    async generateAdditionalSettingsSections(detailData) {
      if (!detailData || typeof detailData !== 'object') {
        throw new TypeError("Invalid detailData argument");
      }
      const credentialId = this.getCredentialTypeId(detailData);
      let uiResults = await this.getLocalDataService().ui.listSection2UIElementsByRuleId(
        detailData.RuleId,
        credentialId,
        detailData["mw-cloud-location"]
      );
      const uiSections = uiResults.uiSections;
      /* istanbul ignore next */
      const doNothingFn = function () { };
      const uiGeneratorArgs = {
        uiSections: uiSections,
        settingsMap: uiResults.settingsMap,
        dependentOptionHandlerFn: doNothingFn,
        inEditMode: false,
        baseElement: null,
        fieldValidationHandlers: null,
        dduxLogger: this.getDataService().logging,
        dataGetters: {
          getCredentialTypeId: this.getCredentialTypeId(detailData),
          getCloudLocation: () => { return detailData["mw-cloud-location"]; },
          getCredentialId: () => { return detailData["mw-credential-id"]; },
        },
        dataSetters: {
        },
      };
      const uiGenerator = new UIGenerator(uiGeneratorArgs);
      const additionalDetailSectionNames = uiGenerator.getSectionTitles();

      await this.generateShortSummaryTitle(additionalDetailSectionNames);

      const generatedHTML = uiGenerator.renderDetails(detailData);
      const additionalDetailGeneratedCodeContainer = this.getAdditionalDetailGeneratedCodeContainer();
      if (additionalDetailGeneratedCodeContainer) {
        additionalDetailGeneratedCodeContainer.innerHTML = generatedHTML;
      }
    }

    async generateShortSummaryTitle(additionalDetailSectionNames) {
      if (additionalDetailSectionNames && Array.isArray(additionalDetailSectionNames) && additionalDetailSectionNames.length) {
        let shortAddtionalDetailContainer = this.getShortAddtionalDetailContainer();
        let titlesLength = additionalDetailSectionNames.length;
        let i = 0;
        if (shortAddtionalDetailContainer) {
          const cloudAccessManager = new CloudAccessManager({
            dataService: this.getLocalDataService(),
            configId: this.getConfigId(),
            mwName: this.getName(),
            renderIPMethod: this.renderIP.bind(this)
          });
          let titleSummary = "";
          for (let title of additionalDetailSectionNames) {
            i++;
            titleSummary += title
            if (i < titlesLength) {
              titleSummary += ", ";
            }
          }
          let hasInboundPermission = false;
          try {
            hasInboundPermission = await cloudAccessManager.hasInboundPermission();
          } catch (error) {
            Util.consoleLogInfo("hasInboundPermission", error);
          }
          if (hasInboundPermission) {
            titleSummary += `, ${I18NStringResource.accessSettings}`;
          }
          shortAddtionalDetailContainer.textContent = titleSummary;
        }
      }
    }

    async generateAccessSection() {
      const additionalDetailGeneratedCodeContainer = this.getAdditionalDetailGeneratedCodeContainer();
      const cloudAccessManager = new CloudAccessManager({
        dataService: this.getLocalDataService(),
        configId: this.getConfigId(),
        mwName: this.getName(),
        renderIPMethod: this.renderIP.bind(this)
      });
      const accessDiv = document.createElement('div');
      accessDiv.className = "accessWrapper";
      additionalDetailGeneratedCodeContainer.appendChild(accessDiv);
      cloudAccessManager.setElement(accessDiv);

      try {
        await cloudAccessManager.render();
      } catch (error) {
        /* istanbul ignore next */
        if (status === CloudStatus.Enum.RUNNING) {
          throw error;
        } else {
          /* ignore error and continue */
        }
      }
    }

    async generateOutputsSection(product) {
      if (!SupportedProducts.isValidProduct(product)) {
        throw new TypeError("Invalid product argument");
      }
      const additionalDetailGeneratedCodeContainer = this.getAdditionalDetailGeneratedCodeContainer();
      if (additionalDetailGeneratedCodeContainer) {
        const cloudOutputsManager = new CloudOutputsManager({
          dataService: this.getLocalDataService(),
          configId: this.getConfigId(),
        });
        const outputsDiv = document.createElement('div');
        outputsDiv.className = "outputsWrapper";
        additionalDetailGeneratedCodeContainer.appendChild(outputsDiv);
        cloudOutputsManager.setElement(outputsDiv);
        try {
          if (SupportedProducts.productSupportsOutputs(product)) {
            await cloudOutputsManager.render();
          }
        } catch (error) {
          /* istanbul ignore next */
          if (status === CloudStatus.Enum.RUNNING) {
            throw error;
          } else {
            /* ignore error and continue */
          }
        }
      }
    }

    async renderSummaryDetails(detailData) {
      const generatedCodeContainer = this.getGeneratedCodeContainer();
      if (!generatedCodeContainer) { return; /* left the page already */ }
      const configId = this.getConfigId();
      let allData = detailData;
      if (!allData) {
        allData = await this.getDetailData(configId, true);
      }
      const statusData = allData.cloudData;
      const machineData = allData.allMachinesData;
      const statusInfo = await this.getStatus(configId, true); // use cached data from call to getDetailData above
      const status = statusInfo.status;
      const isStopped = (status === CloudStatus.Enum.TERMINATED);
      this.emptyGeneratedCode();
      let templateHtml = ComputeResourceDetailCodeGenTemplate({
        resourceDetailContainerTitle: I18NStringResource.computeResourceDetailPageSummaryHeading,
        additionalDetailsToggleTooltip: I18NStringResource.additionalDetailsToggleTooltip,
        editLinkTitle: "Edit",
        additionDetailSectionTitle: I18NStringResource.computeResourceDetailPageSummarySubHeading,
        canEdit: isStopped
      });
      generatedCodeContainer.innerHTML = templateHtml;

      this.populateConfigurationSummarySection(detailData); // Step1

      await this.generateAdditionalSettingsSections(detailData); // Step2

      const additionalDetailGeneratedCodeContainer = this.getAdditionalDetailGeneratedCodeContainer();
      if (!isStopped && additionalDetailGeneratedCodeContainer) {
        if (this.getSupportFirewallModification()) {
          await this.generateAccessSection();
        }
        await this.generateOutputsSection(detailData.product);
      }
    }

    /*
      Error and Notification related
    */
    checkAndDisplayErrors(config) {
      if (config && typeof config === 'object' && config.cloudData && config.cloudData.error && Array.isArray(config.cloudData.error) && config.cloudData.error.length && config["mw-name"]) {
        let allErrors = Util.extractNotificationFromCloudErrors(config.cloudData.error);
        if (allErrors) {
          let notificationBtn = this.el.querySelector('button.btn.displayNotificationsBtn');
          if (notificationBtn) {
            notificationBtn.style.display = 'inline-block';
            /* istanbul ignore next */
            notificationBtn.addEventListener(
              'click',
              function (event) { Util.notify("RESOURCE_ERROR", config["mw-name"] + ": " + allErrors); }.bind(this),
              false
            );
          }
        }
      }
    }

    /*
      Termination policy-related methods.
    */

    async refreshCloudResource(config) {
      try {
        if (!CloudResource.isConfigValid(config)) {
          throw new TypeError("Invalid config argument");
        }
        if (this.getCloudResource()) {
          this.getCloudResource().refreshConfigData(config);
        } else {
          const crArgs = {
            dataService: this.getDataService(),
            config: config,
            proxyURL: null,
            fnStopResource: this.pause.bind(this)
          };
          const cloudResource = new CloudResource(crArgs);
          this.setCloudResource(cloudResource);
        }
        return true;
      } catch (error) {
        Util.consoleLogError("refreshCloudResource", error);
        this.setCloudResource(null);
        return false;
      }
    }

    async updateResourceCountdownTimer(configId, useCache = false) {
      try {
        if (!configId) {
          throw new TypeError("Invalid configId argument");
        }
        if (this.getStatusAndCountdown() && !this.getStatusAndCountdown().getSupportsTermPolicy()) {
          throw new Error("Does not support termination policy");
        }
        const config = await this.getLocalDataService().ui.getLatestConfigData(configId);
        // refresh, or create new cloudResource.  Get updated status from it.
        const refreshSuccessful = await this.refreshCloudResource(config);
        if (!refreshSuccessful) {
          throw new Error("Failed to update countdown timer due to error refreshing CloudResource object.");
        }
        const status = this.getCloudResource().getStatus();
        const termPolicy = this.getCloudResource().getTermPolicy();
        this.getTimerDropdown().setTerminationPolicy(termPolicy);
        await this.getStatusAndCountdown().updateCountdownTimer(configId, status, this.getCloudResource());
      } catch (error) {
        if (error.message === "Does not support termination policy") {
          Util.consoleLogInfo("updateResourceCountdownTimer", error);
        } else {
          Util.consoleLogError("updateResourceCountdownTimer", error);
        }
      } finally {
        this.enableActionButton('clone');
      }
    }

    /*
      Edit-related methods.
      These methods work with, or are used by, the EditConfigForm instance.
    */
    updateEditOrigin(data) {
      if (data && typeof data === 'object' && EditSource.isValidEditSource(data.editOrigin)) {
        const origin = EditSource.valueOf(data.editOrigin);
        this.setEditOrigin(origin);
      } else {
        this.setEditOrigin(EditSource.Enum.DETAILS_PAGE);
      }
    }

    getReleaseText(detailData) {
      let releaseText = "";
      if (detailData && typeof detailData === 'object' && typeof detailData.version === 'string') {
        if (RuleManager.isEntitledToUseRelease(detailData.version)) {
          releaseText = detailData.version;
        } else {
          releaseText = `${detailData.version} ${I18NStringResource.notEntitled}`;
        }
      }
      return releaseText;
    }

    async getEditConfigData(configId) {
      if (!configId || typeof configId !== 'string') {
        throw new TypeError("Invalid configId argument");
      }
      // Get the data of the config we will edit, which is the current config.
      let detailData;
      try {
        detailData = await this.getDetailData(configId, !this.isConfigChanged());
      } catch (error) {
        detailData = null;
      }
      if (!detailData || typeof detailData !== 'object' || !("version" in detailData)) { //NOSONAR
        Util.notify("ERROR", I18NStringResource.dataServiceErrorUnableToGetData);
        throw new Error("Invalid detailData received");
      }
      return detailData;
    }

    getNetworkSelectIdsByProduct(product, platform = 'aws') {
      return SupportedProducts.getNetworkSelectIdsByProduct(product, platform);
    }

    getSelectValueByIdFromData(selectId, detailData) {
      const selectElement = this.el.querySelector(`select#${selectId}`);
      const selectValue = (selectElement ? selectElement.value : detailData[selectId]);
      return selectValue;
    }

    getSelectValueById(selectId, defaultValue = "") {
      const selectElement = this.el.querySelector(`select#${selectId}`);
      const selectValue = (selectElement ? selectElement.value : defaultValue);
      if (!selectElement) {
        Util.consoleLogWarning("getSelectValueById", `select#${selectId} not found`);
      }
      return selectValue;
    }

    getDependentOptionDataBuilderForEdit(detailData, credId, location) {
      const platform = detailData.cloud_provider;
      const [vpcId, subnetId] = this.getNetworkSelectIdsByProduct(detailData.product, platform);
      // Get needed values and create OptionDataBuilder instance.
      const vpc = this.getSelectValueByIdFromData(vpcId, detailData);
      const subnet = this.getSelectValueByIdFromData(subnetId, detailData);
      /* istanbul ignore next */
      const optionDataBuilderArgs = {
        getCredentialTypeId: function () { return credId; },
        getCloudLocation: function () { return location; },
        getVPC: function () { return this.getSelectValueById(vpcId, vpc); }.bind(this),
        getSubnet: function () { return this.getSelectValueById(subnetId, subnet); }.bind(this),
        dataService: this.getLocalDataService()
      };
      const optionDataBuilder = new DependentOptionDataBuilder(optionDataBuilderArgs);
      return optionDataBuilder;
    }

    setActionButtonContainerVisibility(hide = false) {
      const detailsPageActionButtonContainer = this.el.querySelector('div.actionIconContainer.detailsPage');
      if (detailsPageActionButtonContainer) {
        if (hide) {
          detailsPageActionButtonContainer.style.display = 'none';
        } else {
          detailsPageActionButtonContainer.style.display = 'block';
        }
      }
    }

    adjustUIBasedOnEditOrigin() {
      // If goint to edit from outside Details Page, hide spinner and show detailsContainer
      if (this.getEditOrigin() !== EditSource.Enum.DETAILS_PAGE) {
        const busySpinner = this.el.querySelector('div#detailsBusySpinnerContainer');
        const detailsContainer = this.el.querySelector('div.computeDetailsContainer');
        if (busySpinner) {
          busySpinner.style.display = "none";
        }
        if (detailsContainer) {
          detailsContainer.style.display = "block";
        }
      }
    }

    async renderEditFormInsideEditContainer(detailData) {
      // Get the UI elements data needed for step2 of edit form (same as step2 of wizard).
      const credId = this.getCredentialTypeId(detailData);
      const location = detailData["mw-cloud-location"];
      const step2UIElementData = await this.getLocalDataService().ui.listSection2UIElementsByRuleId(detailData.RuleId, credId, location);
      const optionDataBuilder = this.getDependentOptionDataBuilderForEdit(detailData, credId, location);

      // Create the edit form instance
      const editForm = new EditConfigForm({
        currentConfigSettings: this.getCurrentEditConfigSettings(),
        step2UIElementData: step2UIElementData,
        optionDataBuilder: optionDataBuilder,
        updateConfigMethod: this.updateConfig.bind(this),
        closeMethod: this.closeEditForm.bind(this),
        dduxLogger: this.getDataService().logging
      });

      // Get the element that will contain all the edit UI
      const editFormContainer = this.getEditFormContainer();
      if (editFormContainer) {
        editFormContainer.innerHTML = '';
      }
      // Hide the details UI and show the Edit form UI, rendering the edit form instance.
      const generatedCodeContainer = this.getGeneratedCodeContainer();
      if (editFormContainer && generatedCodeContainer && editForm) {
        generatedCodeContainer.style.display = "none";
        editFormContainer.style.display = "block";
        const formWrapper = document.createElement('div');
        formWrapper.className = "formwrapper";
        editFormContainer.append(formWrapper);
        editForm.setElement(formWrapper);
        await editForm.render();
      }
    }

    async edit(event, data) {
      this.processEvent(event);
      // determine and save from where the edit was originated (e.g. resource page)
      this.updateEditOrigin(data);
      // disable the edit and start buttons so the user can't keep hitting them.
      // we're already here because they hit the edit button once.
      this.disableActionButton('edit');
      this.disableActionButton('start');
      try {
        // get, adjust, and save detailData
        const configId = this.getConfigId();
        const detailData = await this.getEditConfigData(configId);
        detailData.releaseDisplayText = this.getReleaseText(detailData);
        this.setCurrentEditConfigSettings(detailData); // store it.
        // The edit form has its own buttons.  We need to hide our buttons now, before rendering the edit form.
        this.setActionButtonContainerVisibility(true);
        // If goint to edit from outside Details Page, hide spinner and show detailsContainer
        this.adjustUIBasedOnEditOrigin();
        // create and render the edit form
        await this.renderEditFormInsideEditContainer(detailData);
      } catch (error) {
        Util.consoleLogError("edit", error);
        this.enableActionButton('start');
        this.enableActionButton('edit');
      }
    }

    closeEditForm() {
      const detailsContainer = this.getGeneratedCodeContainer();
      const editFormContainer = this.getEditFormContainer();
      // hide the edit form and show the details page.
      if (detailsContainer && editFormContainer) {
        editFormContainer.style.display = "none";
        detailsContainer.style.display = "block";
      }
      // clear edit details
      this.setCurrentEditConfigSettings(null);
      // Show the Details Page action buttons
      this.setActionButtonContainerVisibility();
      // restore buttons
      this.enableActionButton('edit');
      this.enableActionButton('start');
      // if edit was initiated on resource page, return to it.
      if (this.getEditOrigin() === EditSource.Enum.RESOURCE_PAGE) {
        this.goToResource();
      }
    }

    isConfigChanged() { return this.configChanged; }
    setConfigChanged(changed) { this.configChanged = (changed === true); }

    async updateConfig(data, startResource = false) {
      let configId = this.getConfigId();
      try {
        if (data) {
          let params = {};
          let entries = data.entries();
          let entry = entries.next();
          while (!entry.done) {
            let id = entry.value[0];
            let value = _.unescape(entry.value[1]);
            params[id] = value;
            entry = entries.next();
          }
          try {
            const currentData = await this.getDetailData(configId, false);
            let resultJson = await this.getLocalDataService().workflow.update(configId, params, false, currentData.product);
            this.setConfigChanged(true);
            Util.notify("NORMAL", DojoString.substitute(I18NStringResource.computeDetailsUpdatedConfig, [this.getName()]));
            const updatedData = await this.refreshPage();
            if (this.getTimerDropdown()) {
              const originalTerminationPolicy = currentData["mw-termination-policy"];
              const newTerminationPolicy = updatedData["mw-termination-policy"];
              if (newTerminationPolicy !== originalTerminationPolicy) {
                this.getTimerDropdown().setTerminationPolicy(newTerminationPolicy);
                this.getTimerDropdown().updateStatusAndCountdown();
              }
            }
          } catch (error) {
            Util.consoleLogError("workflow.update", error);
            Util.notify("ERROR", DojoString.substitute(I18NStringResource.computeDetailsUpdateConfigFailed, [this.getName()]));
          }
        } else {
          Util.notify("NORMAL", `${this.getName()}: ${I18NStringResource.computeDetaiilsNoChangesMade}`);
        }
        if (startResource) {
          Util.notify("INFO", `${this.getName()}: ${I18NStringResource.computeDetailsWaitWhileResourceStarts}`);
          if (this.getEditOrigin() !== EditSource.Enum.DETAILS_PAGE) {
            await this.start();
          } else {
            this.start();
          }
        }
      } catch (generalError) {
        Util.consoleLogError("updateConfig", generalError);
      } finally {
        this.closeEditForm();
      }
    }

    async lookupTerminationPolicyDetailsFromRule(detailData) {
      let supportsTermPolicy = false;
      let supportsIdleTermPolicy = false;
      let termPolicyDropdownValues = {};

      if (detailData && detailData.RuleId && detailData["mw-cloud-location"]) {
        const ruleData = await this.getLocalDataService().workflow.getRuleById(detailData.RuleId, detailData["mw-cloud-location"], true, true, true);
        if (ruleData && ruleData.length && ruleData[0].params && ruleData[0].params.body && ruleData[0].params.body.params) {
          for (const ruleParam of ruleData[0].params.body.params) {
            if (ruleParam.id === "mw-termination-policy") {
              supportsTermPolicy = true;
              termPolicyDropdownValues = ruleData[0].params[ruleParam.constraints.setting_include];
              for (const k of Object.keys(termPolicyDropdownValues)) {
                if (k === "on_idle") {
                  supportsIdleTermPolicy = true;
                  break;
                }
              }
              break;
            }
          }
        }
      }

      return { supportsTermPolicy, supportsIdleTermPolicy, termPolicyDropdownValues };
    }



  }

  return ComputeResourceDetailsPage;
}); // require


