/*
 * Convert server JSON to usable entity
 */
define([
  "service/datatransform/uiElementUtil",
  "service/datatransform/uiSubElementInfo"
], function( UIElementUtil, UISubElementInfo ) {

  class UIElementInfo {

    constructor (data, settings = {}, desiredValues = null) {
      if (! (data && typeof data === 'object' && "config" in data &&
             typeof data.config === 'object' && "ui_element" in data.config &&
             typeof data.config.ui_element === 'object')) {
        throw new TypeError("Invalid input data argument");
      }
      this.desiredValues = desiredValues;
      let config = data.config;
      let uiData = config.ui_element;
      let customData = uiData.custom_element || {};
      let constraints = data.constraints || {};
      this.isCustomElement = (data.type === 'custom_element' && uiData.type === 'custom');
      if (this.isCustomElement) {
        const subElementData = customData.sub_elements || [];
        this.subElements = this.getSubElements(subElementData, settings, desiredValues);
        this.customElementInfo = customData;
      }
      this.elementType = (UIElementUtil.shouldBeSelectElement(data.type, uiData.type) ? 'select' : uiData.type);
      if (uiData.type === 'checkbox') {
        this.checkboxValues = [];
      }
      this.id = data.id;
      this.name = data.id;
      this.checked = Boolean(uiData.checked);
      if (uiData.type === 'checkbox') {
       if (config.default !== ""  && config.default !== undefined) {
         if (typeof config.default === 'string') {
           const def = config.default.toLowerCase();
           if (def === 'true') {
             this.checked = true;
           } else if (def === 'false') {
             this.checked = false;
           }
         } else if (typeof config.default === 'boolean') {
           this.checked = config.default;
         }
       }
      }
      if (data.type === 'radio' && data.radio_group) {
        this.name = data.radio_group;
      }
      if (uiData.type === 'textarea' || uiData.type === 'select') {
        this.selection = uiData.multiple ? 'multiple' : 'single';
        let visibleLines = 1;
        if (uiData.lines && ! isNaN(uiData.lines)) {
          visibleLines = parseInt(uiData.lines);
        }
        this.visibleLines = visibleLines;
      }
      const isBogusDefaultValue = Boolean(constraints.required && uiData.label === config.default);
      this.defaultValue = isBogusDefaultValue ? "" : config.default;
      if (this.desiredValues && this.desiredValues[data.id]) {
        this.defaultValue = this.desiredValues[data.id];
      }
      if (["radio", "checkbox"].indexOf(uiData.type) >= 0) {
        if (uiData.value) {
          this.defaultValue = uiData.value;
        }
        if (uiData.prompt) {
          this.groupTitle = uiData.prompt;
        }
      }
      if ("allowed_values" in uiData && uiData.allowed_values) {
        this.allowedValues = uiData.allowed_values;
      }
      this.prompt = (uiData.prompt && (!config.default || isBogusDefaultValue)) ? uiData.prompt : "";
      this.label = uiData.label || "";
      this.finePrint = uiData.fineprint || "";
      this.value = uiData.value || "";
      this.isOptional = !constraints.required;
      this.formSubmit = customData.form_submit;
      this.disabled = constraints.readonly;
      this.editableAfterStart = constraints["editable_after_start"];
      this.dataQueryType = (UIElementUtil.QUERY_TYPES.includes(data.type) ? data.type : "");
      this.dependentIds = (config.child_param_ids && config.child_param_ids.length) ? config.child_param_ids : undefined;
      this.validation = UIElementUtil.validationFromContraints(constraints);
      if (constraints.setting_include) {
        this.settingsIncludeKey = constraints.setting_include;
        if (uiData.type === 'checkbox') {
          for (const key in settings[this.settingsIncludeKey]) {
            this.checkboxValues.push(String(key));
          }
          if (this.checkboxValues.length !== 2) {
            throw new Error(`Invalid checkbox value set: name: ${this.settingsIncludeKey}, reason: number of properties != 2`);
          }
        }
      }
      if (constraints.setting_exclude) {
        this.settingsExcludeKey = constraints.setting_exclude;
      }
    }

    getSubElements (params, settings = {}, desiredValues = null) {
      const subElements = [];
      for (const param of params) {
        const subElementInfo = new UISubElementInfo(param, settings, desiredValues);
        subElements.push(subElementInfo);
      }
      return subElements;
    }

    /*
      returns something like this:
      {
        "dataType": "UIElementInfo",
        "value": {
          "elementType": "text",
          "id": "CustomIamRole",
          "name": "CustomIamRole",
          "prompt": "",
          "label": "IAM Role",
          "finePrint": "Specify an IAM Role (optional) to associate with this instance.",
          "defaultValue": "",
          "value": "",
          "isOptional": true,
          "disabled": false,
          "checked": false,
          "dataQueryType": "",
          "validation": {}
        }
      }
    */
    toJSON () {
      let value = {};
      for(let i in this) {
        if(typeof this[i] !== 'undefined') {
          value[i] = this[i];
        }
      }
      return { dataType: "UIElementInfo", value: value };
    }

  }

  return UIElementInfo;
});
