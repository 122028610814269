/* jshint esversion: 8 */
define([
    "util",
    "constants",
    "service/platform/platformDataService",
    "service/platform/aws/ec2Service",
    "service/platform/aws/iamService",
    "service/general/mathworksService",
    "service/datatransform/uiElementUtil",
    "dojo/i18n!nls/cloudCenterStringResource",
    "dojo/string"
], function(
    Util,
    CCWA_Constants,
    PlatformDataService,
    EC2Service,
    IAMService,
    MathworksService,
    UIElementUtil,
    I18NStringResource,
    DojoString
) {

  /**
   * AWSDataService handles all AWS-specific interaction with Cloud Center server via DAO.
   */
  class AWSDataService extends PlatformDataService {

    constructor (args) {
        super(args);
        this.ec2Service = new EC2Service({dao: this.getDAO()});
        this.iamService = new IAMService({dao: this.getDAO()});
        this.mathworksService = new MathworksService({dao: this.getDAO()});
    }

    getEC2Service () { return this.ec2Service; }
    getIAMService () { return this.iamService; }
    getMathworksService () { return this.mathworksService; }

    /*
     * Required API
    */

    async getDependentOptionFields (queryType, options = {}) {
        const optionFields = {
            display: undefined,
            value: undefined
        };
        if (UIElementUtil.QUERY_TYPES.includes(queryType)) {
            switch (queryType) {
                case "aws_ec2_network":
                    optionFields.display = ["ID", "IPv4", "Name"];
                    optionFields.value = "ID";
                    break;
                case "aws_ec2_subnet":
                    optionFields.display = ["AvailabilityZone", "Type", "SubnetID", "IPv4", "Tags.Name"];
                    optionFields.value = "SubnetID";
                    if (options.supportPrivateSubnets !== "true") { //rules.params.support_private_subnets is a string
                        optionFields.disabled = {name:"Type",values:{"private":true}};
                    }
                    break;
                case "aws_ec2_instance_type":
                    optionFields.display = "";
                    optionFields.value = "";
                    break;
                case "aws_ec2_keypair":
                    optionFields.display = "KeyName";
                    optionFields.value = "KeyName";
                    break;
                case "aws_ec2_region":
                    optionFields.display = "";
                    optionFields.value = "";
                    break;
                case "aws_acm_certificate_arn":
                    optionFields.display = "UUID"; // artficial field
                    optionFields.value = "CertificateArn";
                    break;
                case "aws_mwa_license_entitlement":
                    optionFields.display = ["id", "label"];
                    optionFields.value = "id";
                    break;
                default:
            }
        }
        return optionFields;
      }
      
      async findMachineType (credId, rulesId, machineType) { 
        const credIdValid = (credId && typeof credId === 'string');
        if (!credIdValid) {
            throw new TypeError("Invalid credId argument");
        }
        const rulesIdValid = (rulesId && typeof rulesId === 'string');
        if (!rulesIdValid) {
            throw new TypeError("Invalid rulesId argument");
        }
        const machineTypeValid = (machineType && typeof machineType === 'string');
        if (!machineTypeValid) {
            throw new TypeError("Invalid machineTypeValid argument");
        }
        return this.getDAO().getCCAPI("workflow", "resource", rulesId, "machine-type-availability", {"credential_id":credId, "desired_machine_type":machineType, refresh:"false"}, false);
    }

    async getDependentOptionData (queryType, credId, options = {}) {
        let optionData;
        if (UIElementUtil.QUERY_TYPES.includes(queryType)) {
            try {
                switch (queryType) {
                    case "aws_ec2_network":
                        optionData = await this.getEC2Service().getVPC(credId, options.location);
                        break;
                    case "aws_ec2_subnet":
                        if (!("vpc" in options)) {
                            throw new TypeError("Invalid options.vpc argument");
                        }
                        optionData = await this.getEC2Service().getSubnet(credId, options.location, options.vpc);
                        break;
                    case "aws_ec2_instance_type":
                        if (!("subnet" in options)) {
                            throw new TypeError("Invalid options.subnet argument");
                        }
                        optionData = await this.getEC2Service().getInstanceType(credId, options.location, options.subnet);
                        break;
                    case "aws_ec2_keypair":
                        optionData = await this.getEC2Service().getSSHKey(credId, options.location);
                        break;
                    case "aws_ec2_region":
                        optionData = await this.getEC2Service().getRegion(credId);
                        break;
                    case "aws_acm_certificate_arn":
                        optionData = await this.getEC2Service().getSSLCertificate(credId, options.location);
                        // create artificial field
                        for (const item of optionData.msg) {
                          const arn = item.CertificateArn;
                          const uuid = Util.extractUUIDFromARN(arn);
                          item["UUID"] = uuid;
                        }
                        break;
                    case "aws_mwa_license_entitlement":
                        if (options && options.release && options.product) {
                            optionData = await this.getMathworksService().getMWEntitlements(options.product, options.release);
                        }
                    default:
                }
            } catch (error) {
                Util.consoleLogWarning('getDependentOptionData', error);
                optionData = {msg: []};
            }
        }
        return optionData;
      }

    async renderCredentialList (credentialId, popText, description, data) {
        try {
            const roleARN = data[credentialId]["role-arn"];
            const regex = new RegExp(/^[\w:]+role\/(.*)$/);
            const matchedGroups = regex.exec(roleARN);
            const arnId = matchedGroups[1];
            const arnDetailsUrl = encodeURI(`https://console.aws.amazon.com/iamv2/home#/roles/details/${arnId}`);
            popText = `<h2 class="credPopoverh2">${I18NStringResource.credPopoverAwsAccount}</h2>
                      <p class="credPopoverP">${description}</p>
                      <h3 class="credPopoverh3">${I18NStringResource.credPopoverAwsArn}</h3>
                      <p class="credPopoverP">${roleARN}</p>
                      <p class="credPopoverP">
                      <a href="${arnDetailsUrl}" rel="noopener noreferrer" target="_blank">
                      ${I18NStringResource.credPopoverAwsArnDetails}
                      </a>
                      </p>`;
        } catch (error) {
            Util.consoleLogWarning(`renderList aws`, error);
            popText = `<div><div class="credPopoverWarningIcon"></div><span></span>${I18NStringResource.credPopoverErrorMessage}</span></div>`;
        }
        return popText;
    }

    async listCredentials (credentialTypeId, includeSubscriptions = false) {
        let rawData = {};
        Util.consoleLogTrace("listCredentials", "getting server data");
        if (! (credentialTypeId && typeof credentialTypeId === 'string')) {
            throw new TypeError("Invalid credentialTypeId argument");
        }
        try {
            rawData = await this.getIAMService().list(credentialTypeId);
        } catch (error) {
            throw new Error(DojoString.substitute(I18NStringResource.dataServiceErrorServerErrorWithInfo, [error.message]));
        }
        return rawData;
    }

    async deleteCredential (credentialId, credTypeId, configs = []) {
        const platform = Util.getPlatformFromCredentialTypeId(credTypeId);
        if (platform !== "aws") {
            throw new TypeError(I18NStringResource.credentialsPageErrorCannotFindCredentialTypeID);
        }
        // See if credential is in use by CC2 resources and fail if it is
        let names = await Util.collectExistingConfigNames(configs)
        if (names) {
          throw new Error(DojoString.substitute(I18NStringResource.credentialsPageUnableToDelete, [names]));
        }
        // Made it to here, do credential delete depending on what credential this is
        switch (credTypeId) {
          case CCWA_Constants.AMAZON_AWS_CREATE_ROLE_CREDENTIAL_ID:
          case CCWA_Constants.ALL_TOGETHER_NOW_CREDENTIAL_TYPE_ID:
          case CCWA_Constants.AMAZON_AWS_CFE_DEMOS_CREDENTIAL_ID:
            return await this.getIAMService().delete(credentialId);
          default:
            throw new TypeError(I18NStringResource.credentialsPageErrorCannotFindCredentialTypeID);
        }
    }

    async getCredential (credentialId) {
        if (!credentialId || typeof credentialId !== 'string') {
            throw new TypeError("Invalid credentialId argument");
        }
        return await this.getIAMService().get(credentialId);
    }

    async updateCredentialDescription (credentialId, credentialTypeId, description) {
        if (!credentialId || typeof credentialId !== 'string') {
            throw new TypeError("Invalid credentialId argument");
        }
        if (!credentialTypeId || typeof credentialTypeId !== 'string') {
            throw new TypeError("Invalid credentialTypeId argument");
        }
        if (typeof description !== 'string') {
            throw new TypeError("Invalid description argument");
        }
        const platform = Util.getPlatformFromCredentialTypeId(credentialTypeId);
        if (platform !== "aws") {
            throw new TypeError(I18NStringResource.credentialsPageErrorCannotFindCredentialTypeID);
        }
        try {
            switch(credentialTypeId) {
            case CCWA_Constants.AMAZON_AWS_CREATE_ROLE_CREDENTIAL_ID:
            case CCWA_Constants.ALL_TOGETHER_NOW_CREDENTIAL_TYPE_ID:
            case CCWA_Constants.AMAZON_AWS_CFE_DEMOS_CREDENTIAL_ID:
                await this.getIAMService().editCredential(credentialId, description);
                break;
            default:
                throw new TypeError(I18NStringResource.credentialsPageErrorCannotFindCredentialTypeID);
            }
        } catch (error) {
            const errMsg = error.message || JSON.stringify(error);
            throw new Error(DojoString.substitute(I18NStringResource.credentialsPageErrorUpdateFailed, [errMsg]));
        }
    }

    async createCredential (createCredArgs) {
        if (!createCredArgs || typeof createCredArgs !== 'object') {
            throw new TypeError("Invalid createCredArgs argument");
        }
        if (!createCredArgs.credentialId || typeof createCredArgs.credentialId !== 'string') {
            throw new TypeError("Invalid createCredArgs.credentialId value");
        }
        const credentialId = createCredArgs.credentialId;

        if (!createCredArgs.accessKeyId || typeof createCredArgs.accessKeyId !== 'string') {
            throw new TypeError("Invalid createCredArgs.accessKeyId value");
        }
        const accessKeyId = createCredArgs.accessKeyId;

        if (!createCredArgs.secretAccessKey || typeof createCredArgs.secretAccessKey !== 'string') {
            throw new TypeError("Invalid createCredArgs.secretAccessKey value");
        }
        const secretAccessKey = createCredArgs.secretAccessKey;

        if (createCredArgs.sessionToken && typeof createCredArgs.sessionToken !== 'string') {
            throw new TypeError("Invalid createCredArgs.sessionToken value");
        }
        const sessionToken = createCredArgs.sessionToken;

        return await this.getIAMService().createRole(credentialId, accessKeyId, secretAccessKey, sessionToken);
    }

    async importCredential (importCredArgs) {
        if (!importCredArgs || typeof importCredArgs !== 'object') {
            throw new TypeError("Invalid importCredArgs argument");
        }
        if (!importCredArgs.credentialId || typeof importCredArgs.credentialId !== 'string') {
            throw new TypeError("Invalid importCredArgs.credentialId value");
        }
        const credentialId = importCredArgs.credentialId;

        if (!importCredArgs.roleARN || typeof importCredArgs.roleARN !== 'string') {
            throw new TypeError("Invalid importCredArgs.roleARN value");
        }
        const roleARN = importCredArgs.roleARN;

        // description is optional
        if (importCredArgs.description && typeof importCredArgs.description !== 'string') {
            throw new TypeError("Invalid importCredArgs.description value");
        }
        const description = importCredArgs.description;

        return await this.getIAMService().importRole(credentialId, roleARN, description);
    }

    async getAccountIdFromConfig (config) {
        let accountId = "";
        if (config && typeof config === 'object') {
            if (config.cloud && config.cloud.account && config.cloud.account.account_id) {
                accountId = config.cloud.account.account_id;
            }
        }
        return accountId;
    }

    async getCredentialDataFromAccountId (accountId, credentialTypeId, userCredentials) {
        const desiredCredentialData = {};
        if (!accountId || typeof accountId !== 'string') {
            throw new TypeError("Invalid accountId argument");
        }
        if (!Util.isMD5(credentialTypeId)) {
            throw new TypeError("Invalid credentialTypeId argument");
        }
        if (!userCredentials || typeof userCredentials !== 'object') {
            throw new TypeError("Invalid userCredentials argument");
        }
        for(const key in userCredentials) {
          const credentialData = userCredentials[key];
          if (Util.getPlatformFromCredentialPlatform(credentialData.platform) !== 'aws') {
            continue;
          }
          if ("account" in credentialData) {
            if (credentialData.account === accountId && credentialData["credtype-id"] === credentialTypeId) {
              desiredCredentialData.credentialId = key;
              desiredCredentialData.credentialTypeId = credentialData["credtype-id"];
              desiredCredentialData.account = credentialData.account;
              desiredCredentialData.platform = Util.getPlatformFromCredentialPlatform(credentialData.platform);
              break;
            }
          }
        }
        return desiredCredentialData;
    }

  }
  return AWSDataService;
});