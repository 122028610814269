/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "backbone",
  "wizard/abstractWizardStep",
  "wizard/uiGenerator",
  "wizard/dynamic/dependentOptionDataBuilder",
  "service/datatransform/dependentOptionInfo",
  "service/datatransform/ruleInfo",
  "util",
  "supportedProducts",
  "computeResource/computeValueFormatter",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string"
], function (_, $, Backbone, AbstractWizardStep, UIGenerator,
  DependentOptionDataBuilder, DependentOptionInfo, RuleInfo, Util,
  SupportedProducts, ComputeValueFormatter,
  I18NStringResource, DojoString) {

  class CreateMATLABWizardStep2 extends AbstractWizardStep {

    constructor(args) {
      super(args);
      if (!(args.searchCriteria && args.searchCriteria instanceof RuleInfo)) {
        throw new TypeError("Invalid searchCriteria argument");
      }
      this.searchCriteria = args.searchCriteria;
      if (!(args.hiddenFieldData && Array.isArray(args.hiddenFieldData))) {
        throw new TypeError("Invalid hiddenFieldData argument");
      }
      this.hiddenFieldData = args.hiddenFieldData;
      this.uiGenerator = null;
      this.rulesManager = args.rulesManager;
      this.savedStepValues = args.savedValues;
      this.cloudPlatform = args.cloudPlatform;
      this.updateSavedStep1ValueMethod = args.updateSavedStep1ValueMethod;
      this.getSavedStep1ValuesMethod = args.getSavedStep1ValuesMethod;
      this.onStep1ChangeMethod = args.onStep1ChangeMethod;
      this.savedStep2ValueOverrides = {};
    }

    /*
     *  Query selector-based getters
     */
    getCredentialTypeIdInputValue() {
      let value = "";
      const credTypeIdInput = this.el.querySelector('input[name="mw-credential-id"]');
      if (credTypeIdInput) {
        value = credTypeIdInput.value;
      } else {
        Util.consoleLogWarning("getCredentialTypeIdInputValue", "input[name=\"mw-credential-id\"] not found");
      }
      return value;
    }
    getCredentialTypeIdInputValueMethod() { return this.getCredentialTypeIdInputValue.bind(this); }
    getCloudLocationValue() {
      let value = "";
      const cloudLocationInput = this.el.querySelector('input[name="mw-cloud-location"]');
      if (cloudLocationInput) {
        value = cloudLocationInput.value;
      } else {
        Util.consoleLogWarning("getCloudLocationValue", 'input[name="mw-cloud-location"] not found');
      }
      return value;
    }
    getCloudLocationValueMethod() { return this.getCloudLocationValue.bind(this); }
    getCredentialIdInputValue() {
      let value = "";
      //This looks the same as the value in "getCredentialTypeIdInputValue" but is for the cloud account, not the type
      const credIdInput = this.el.querySelector('input[name="mw-credential-id"]');
      if (credIdInput) {
        value = credIdInput.value;
      } else {
        Util.consoleLogWarning("getCredentialIdInputValue", "input[name=\"mw-credential-id\"] not found");
      }
      return value;
    }
    getCredentialIdInputValueMethod() { return this.getCredentialIdInputValue.bind(this); }
    getRulesId() {
      let value = "";
      const searchCriteria = this.getSearchCriteria();
      if (searchCriteria) {
        value = searchCriteria.id;;
      } else {
        Util.consoleLogWarning("getRulesId", 'this.getSearchCriteria() not found');
      }
      return value;
    }
    getRulesIdMethod() { return this.getRulesId.bind(this); }
    getAllStep2Selects() {
      return Array.from(this.el.querySelectorAll('div#step2Container select'));
    }
    getStep2GeneratedCodeContainer() {
      return this.el.querySelector('div#step2ContentContainer.generatedCodeContainer');
    }

    getCurrentLocation() {
      let value = "";
      const searchCriteria = this.getSearchCriteria();
      if (searchCriteria) {
        value = searchCriteria.cloudLocations;
      } else {
        Util.consoleLogWarning("getCurrentLocation", 'this.getSearchCriteria() not found');
      }
      return value;
    }
    getCurrentLocationMethod() { return this.getCurrentLocation.bind(this); }

    getCurrentCredentialId() {
      let value = "";
      const uiGenerator = this.getUIGenerator();
      if (uiGenerator) {
        const selector = this.getUIGenerator().selectorMap.get("mw-credential-id").selector
        const input = this.el.querySelector(selector);
        value = input.value;
      } else {
        Util.consoleLogWarning("getCurrentCredentialId", 'this.getUIGenerator() not found');
      }
      return value;

    }
    getCurrentCredentialIdMethod() { return this.getCurrentCredentialId.bind(this); }

    updateSavedStep2ValueOverride(elementId, fieldValue) {
      this.getSavedStep2ValueOverrides()[elementId] = fieldValue;
    }
    updateSavedStep2ValueOverrideMethod() { return this.updateSavedStep2ValueOverride.bind(this); }
    resetSavedStep2ValueOverrides() {
      this.savedStep2ValueOverrides = {};
    }
    getSavedStep2ValueOverrides() {
      return this.savedStep2ValueOverrides;
    }
    getSavedStep2ValueOverridesMethod() { return this.getSavedStep2ValueOverrides.bind(this); }

    getCurrentStep2ValuesMethod() { return this.getStepValues.bind(this); }

    /*
     *  Getters and Setters
     */

    getCloudPlatform() { return this.cloudPlatform; }

    getSavedStepValues() { return this.savedStepValues; }

    getSearchCriteria() { return this.searchCriteria; }

    getHiddenFieldData() { return this.hiddenFieldData; }

    getRulesManager() { return this.rulesManager; }

    getHiddenFieldValues() {
      let fields = this.getHiddenFieldData();
      let valueMap = new Map();
      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        valueMap.set(field.id, field.value);
      }
      return valueMap;
    }

    getUIGenerator() {
      return this.uiGenerator;
    }
    setUIGenerator(generator) {
      this.uiGenerator = generator;
    }

    getNetworkSelectIdsByProduct(product, platform = 'aws') {
      return SupportedProducts.getNetworkSelectIdsByProduct(product, platform);
    }

    getSelectValueById(selectId) {
      // custom elements might not use selects for this data
      const selectElement = this.el.querySelector(`#${selectId}`);
      const selectValue = (selectElement ? selectElement.value : "");
      if (!selectElement) {
        Util.consoleLogInfo("getSelectValueById", `select#${selectId} not found`);
      }
      return selectValue;
    }

    getDependentOptionDataBuilder(product) {
      const [vpcId, subnetId] = this.getNetworkSelectIdsByProduct(product, this.getCloudPlatform());
      /* istanbul ignore next */
      const optionDataBuilderArgs = {
        getCredentialTypeId: this.getCredentialTypeIdInputValueMethod(),
        getCloudLocation: this.getCloudLocationValueMethod(),
        getCredentialId: this.getCredentialIdInputValueMethod(),
        getRulesId: this.getRulesIdMethod(),
        getCurrentLocation: this.getCurrentLocationMethod(),
        getCurrentCredentialId: this.getCurrentCredentialIdMethod(),
        getSavedStep1Values: this.getSavedStep1ValuesMethod, //method was bound in args to constructor
        getSavedStep2ValueOverrides: this.getSavedStep2ValueOverridesMethod(),
        getCurrentStep2Values: this.getCurrentStep2ValuesMethod(),
        updateSavedStep1Value: this.updateSavedStep1ValueMethod, //method was bound in args to constructor
        updateSavedStep2ValueOverride: this.updateSavedStep2ValueOverrideMethod(),
        onStep1Change: this.onStep1ChangeMethod, //method was bound in args to constructor

        getVPC: function () {
          return this.getSelectValueById(vpcId);
        }.bind(this),
        getSubnet: function () {
          return this.getSelectValueById(subnetId);
        }.bind(this),
        getSavedValues: function () { return this.getStepValues(); }.bind(this),
        getUpdateSummary: this.getUpdateSummaryMethod(),
        dataService: this.getDataService()
      };
      const optionDataBuilder = new DependentOptionDataBuilder(optionDataBuilderArgs);
      return optionDataBuilder;
    }

    emptySection2Content() {
      if (this.getUIGenerator()) {
        this.resetConfigSummary();
        let generatedUIContainer = this.el;
        this.getUIGenerator().stop();
        while (generatedUIContainer.lastChild) { generatedUIContainer.lastChild.remove(); }
        this.setUIGenerator(null);
      }
    }

    resetConfigSummary() {
      let uiGenerator = this.getUIGenerator();
      let selectorMap = uiGenerator.getInputSelectorMap();
      let configSummary = this.getConfigInfoSummary();
      if (selectorMap && selectorMap.size) {
        let values = selectorMap.values();
        let data = values.next();
        while (!data.done) {
          let value = data.value;
          let inputId = value.id;
          if (configSummary.has(inputId)) {
            configSummary.delete(inputId);
          }
          data = values.next();
        }
      }
    }

    setVisibilityOfButtons(buttonsVisible) {
      let value = (buttonsVisible === true) ? "visible" : "hidden";
      let parentElement = (this.el && this.el.parentElement) ? this.el.parentElement : null;
      let buttonContainer = (parentElement && parentElement.nextElementSibling) ? parentElement.nextElementSibling : null;
      if (buttonContainer && buttonContainer.classList.contains("buttonContainer")) {
        buttonContainer.style.visibility = value;
      }
    }

    getUpdateSummaryMethod() {
      return this.getConfigInfoSummary().setSummaryTargetValue.bind(this.getConfigInfoSummary());
    }

    getStepValues() {
      const overrides = this.getSavedStep2ValueOverrides();
      const stepValues = { visible: {}, hidden: {} };
      const section2Selectors = this.getUIGenerator().getInputSelectorMap();
      const entries = section2Selectors.values();
      let entry = entries.next();
      while (!entry.done) {
        const data = entry.value;
        const fieldId = data.id;
        const element = this.el.querySelector(data.selector);
        const isDisabled = element ? Boolean(element.disabled) : true;
        const override = overrides[fieldId]
        if (isDisabled && !override) {
          entry = entries.next();
          continue;  // skip read-only elements
        }
        let fieldValue;
        let type = 'visible';
        if (element) {
          if (element.dataset && element.dataset.searchresult) {
            fieldValue = element.dataset.searchresult;
          } else if (element.type === 'hidden') {
            type = 'hidden';
            fieldValue = element.value;
          } else {
            fieldValue = Util.getVisibleElementItem(element);
          }
        }
        if (override) {
          fieldValue = override;
        }
        stepValues[type][fieldId] = fieldValue;
        entry = entries.next();
      }
      return stepValues;
    }

    async initializeStep() {
      let generatedUIContainer = this.el;
      let uiElementInfosBySection;
      let settingsMap;
      let generatedUI;
      try {
        this.setVisibilityOfButtons(false); // hide buttons to start
        // Order is important
        const credId = this.getHiddenFieldValues().get("mw-credential-id");
        let ruleId = this.getSearchCriteria().id;
        let location = this.getSearchCriteria().cloudLocations;
        const desiredValues = this.getDesiredStepValues();
        if (desiredValues) {
          if (desiredValues.ruleId) {
            ruleId = desiredValues.ruleId;
          }
          if (desiredValues["mw-cloud-location"]) {
            location = desiredValues["mw-cloud-location"];
          }
        }
        let uiResults;
        // what is called via our dataService goes through the client cache.
        // call listSection2UIElementsByRuleId from our cached dataService if we have the necessary arg.
        if (ruleId && location) {
          uiResults = await this.getDataService().ui.listSection2UIElementsByRuleId(ruleId, credId, location, desiredValues);
        } else {
          uiResults = await this.getDataService().ui.listSection2UIElements(this.getSearchCriteria(), credId);
        }
        uiElementInfosBySection = uiResults.uiSections;
        this.setFirstInputOnFormSelector(`#${uiElementInfosBySection[0].elements[0].id}`);
        settingsMap = uiResults.settingsMap;

        settingsMap.set("product", this.getSearchCriteria().product)
        settingsMap.set("release", this.getSearchCriteria().release)

        const optionDataBuilder = this.getDependentOptionDataBuilder(this.getRulesManager().getProduct());

        const loggedInInfo = await this.getDataService().getUserService().loggedIn();
        const email = loggedInInfo ? loggedInInfo.email : "";

        const uiGeneratorArgs = {
          uiSections: uiElementInfosBySection,
          settingsMap: settingsMap,
          dependentOptionHandlerFn: optionDataBuilder.loadDependentOptions.bind(optionDataBuilder),
          inEditMode: false,
          baseElement: null,
          fieldValidationHandlers: {
            validFieldHandler: this.validFieldHandler,
            invalidFieldHandler: this.invalidFieldHandler
          },
          dduxLogger: this.getDataService().logging,
          dataGetters: {
            getCredentialTypeId: this.getCredentialTypeIdInputValueMethod(),
            getCloudLocation: this.getCloudLocationValueMethod(),
            getCredentialId: this.getCredentialIdInputValueMethod(),
            getRulesId: this.getRulesIdMethod(),
            getCurrentLocation: this.getCurrentLocationMethod(),
            getCurrentCredentialId: this.getCurrentCredentialIdMethod(),
            getSavedStep1Values: this.getSavedStep1ValuesMethod, //method was bound in args to constructor
            getSavedStep2ValueOverrides: this.getSavedStep2ValueOverridesMethod(),
            getCurrentStep2Values: this.getCurrentStep2ValuesMethod()
          },
          dataSetters: {
            updateSavedStep1Value: this.updateSavedStep1ValueMethod, //method was bound in args to constructor
            updateSavedStep2ValueOverride: this.updateSavedStep2ValueOverrideMethod(),
            onStep1Change: this.onStep1ChangeMethod //method was bound in args to constructor
          },
          email: email,
        };
        let uiGenerator = new UIGenerator(uiGeneratorArgs);
        this.setUIGenerator(uiGenerator);
        const valuesToUse = (desiredValues && Object.keys(desiredValues).length > 0) ? desiredValues : null;
        generatedUI = this.getUIGenerator().render(this.getHiddenFieldData(), null, valuesToUse);
        window.scrollTo(0, 0);
        if (generatedUIContainer && generatedUI) {
          let extIp = generatedUI.querySelector("input#ClientIPAddress");
          if (extIp && !extIp.value) {
            const ip = await this.getDataService().ui.externalIp();
            if (ip) {
              extIp.value = ip;
            }
          }
          // Order is important
          generatedUIContainer.style.visibility = "hidden";
          generatedUIContainer.appendChild(generatedUI);
          // Wait for all custom/composite elements to appear in the DOM before proceeding
          await this.getUIGenerator().allCustomElementsAreUpdated();
          let queryMap = this.getUIGenerator().getDependentOptionQueryMap();
          this.getUIGenerator().disableDependentOptions();
          let nonDependentElementsToNotifyOfChangedValue = await optionDataBuilder.loadNonDependentOptions(queryMap, settingsMap, undefined);
          const selectorMap = this.getUIGenerator().getInputSelectorMap();
          let dynamicEvents = this.initializeInputToSummaryFieldMap(selectorMap);
          // add them to our current list of view listeners
          this.mergeDynamicEvents(dynamicEvents);
          // re-initialize our view listeners.
          // This will drop all current listeners and than add the new list of listeners
          this.delegateEvents();
          this.getUIGenerator().start();
          optionDataBuilder.notifyElementsOfChangedValue(nonDependentElementsToNotifyOfChangedValue);
          optionDataBuilder.updateDependentOptions(queryMap);

          const selectElements = this.getAllStep2Selects();
          if (selectElements.length) {
            for (let i = selectElements.length - 1; i >= 0; i--) {
              //reverse order to skip dependent options that are not enabled
              let selectElement = selectElements[i];
              if (selectElement.disabled !== true && Util.selectFirstNonPromptOption(selectElement)) {
                selectElement.dispatchEvent(new Event("change"));
              }
            }
          }
        }

        Util.initializePopovers();

      } catch (error) {
        const step2GeneratedCodeContainer = this.getStep2GeneratedCodeContainer();
        if (step2GeneratedCodeContainer) {
          Util.consoleLogError("initializeStep", error);
          throw error;
        } // else we left the wizard so no elements are found
      } finally {
        this.setVisibilityOfButtons(true); // show buttons
        if (!this.isAutoCompleteOn()) {
          generatedUIContainer.style.visibility = "visible";
        }
      }
    }

    dynamicChangeHandler(event) {
      if (event && event.target && event.target.id && ("value" in event.target)) {
        let targetId = event.target.id;
        let value = event.target.value;
        let formatter = new ComputeValueFormatter();
        if (value) {
          value = formatter.getDisplayValue(targetId, value);
        }
        this.getConfigInfoSummary().setSummaryTargetValue(targetId, value);
      }
    }

    initializeInputToSummaryFieldMap(map) {
      let dynamicEvents = [];
      if (map && map.size) {
        let values = map.values();
        let data = values.next();
        let formatter = new ComputeValueFormatter();
        while (!data.done) {
          let value = data.value;
          let inputId = value.id;
          let isRequired = value.required;
          let isHidden = value.hidden;
          let isSecret = value.secret;
          let label = value.label ? `${value.label}: ` : `${inputId}: `;
          let selector = value.selector;
          if (!isHidden && !isSecret) {
            let input = this.el.querySelector(selector);
            if (!input) {
              Util.consoleLogWarning("initializeInputToSummaryFieldMap", `Not found: ${selector}`);
            }
            let inputValue = input ? input.value : undefined;
            if (inputValue) {
              inputValue = formatter.getDisplayValue(inputId, inputValue);
            }
            // mapInputIdToSummaryId (inputId, summaryId, isRequired, isHighlighted, labelText, initialValue)
            this.getConfigInfoSummary().mapInputIdToSummaryId(inputId, inputId, isRequired, false, label, inputValue);
            let eventName = "change";
            dynamicEvents[`${eventName} ${selector}`] = this.dynamicChangeHandler.bind(this);
          }
          data = values.next();
        }
      }
      return dynamicEvents;
    }

  }

  return CreateMATLABWizardStep2;
});
