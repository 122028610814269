/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "service/datatransform/uiElementInfo",
  "service/datatransform/uiSubElementInfo",
  "service/datatransform/dependentOptionInfo",
  'service/landingPageNavButtons',
  "util",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "service/datatransform/dataTransformationUtil",
  "wizard/dynamic/dependentOptionManager",
  "validation/inlineElementValidation",
  "templates/detailsCodeGenSectionTemplate",
  "dialogs/inputPromptDialog",
  "config",
  "validation/inlineComponentValidation",
  "components/inlinePopover",
  "components/licenseManagerSimple",
  "components/licenseManager",
  "components/networkAccess",
  "components/sslCertificate",
  "components/autoLogin",
  "components/autologinLicenseManager",
  "components/mwClusterWorkerPicker",
  "components/stackRollbackChoice",
  "components/machineTypeChooserWithFinder",
  "service/cloudStatus"
], function (_, $, UIElementInfo, UISubElementInfo, DependentOptionInfo, LandingPageNavButtons,
  Util, I18NStringResource, DojoString, DataTransformationUtil, DependentOptionManager,
  InlineElementValidation, DetailsSectionTemplate, InputPromptDialog, Config,
  InlineComponentValidation, InlinePopover, LicenceManagerSimple, LicenseManager,
  NetworkAccess, SSLCertificateChooser, AutoLogin, AutoLoginLicenseManager, MWClusterWorkerPicker, StackRollbackChoice, MachineTypeChooserWithFinder, CloudStatus) {

  class UIGenerator {

    constructor(args = {}) {
      if (typeof args !== 'object') {
        throw new TypeError("Invalid args argument");
      }
      this.uiSections = args.uiSections || [];
      if (!Array.isArray(this.uiSections)) {
        throw new TypeError("Invalid uiSections argument");
      }
      this.settingsMap = args.settingsMap || new Map();
      if (!(this.settingsMap instanceof Map)) {
        throw new TypeError("Invalid settingsMap argument");
      }
      this.baseElement = args.baseElement || null;
      if (!this.baseElement) {
        this.baseElement = document.createElement('div');
        this.baseElement.classList.add('baseContainer');
        this.baseElement.id = 'baseContainer';
      }
      if (!(this.baseElement instanceof Element)) {
        throw new TypeError("Invalid baseElement argument");
      }
      this.eventMap = new Map();
      this.selectorMap = new Map();
      if (!(args.dependentOptionHandlerFn && typeof args.dependentOptionHandlerFn === 'function')) {
        throw new TypeError("Invalid dependentOptionHandlerFn argument");
      }
      this.dependentOptionManager = new DependentOptionManager({
        dependentOptionUpdateMethod: args.dependentOptionHandlerFn,
        settingsMap: this.settingsMap
      });
      this.validFieldHandler = () => { };
      this.invalidFieldHandler = () => { };
      if (args.fieldValidationHandlers && typeof args.fieldValidationHandlers === 'object') {
        if (args.fieldValidationHandlers.validFieldHandler && typeof args.fieldValidationHandlers.validFieldHandler === 'function') {
          this.validFieldHandler = args.fieldValidationHandlers.validFieldHandler;
        }
        if (args.fieldValidationHandlers.invalidFieldHandler && typeof args.fieldValidationHandlers.invalidFieldHandler === 'function') {
          this.invalidFieldHandler = args.fieldValidationHandlers.invalidFieldHandler;
        }
      }
      this.dduxLogger = { logData: () => { } };
      if (args.dduxLogger && typeof args.dduxLogger === 'object' && args.dduxLogger.logData && typeof args.dduxLogger.logData === 'function') {
        this.dduxLogger = args.dduxLogger;
      }
      this.customElementUpdatePromises = [];
      this.inlineElementValidation = new InlineElementValidation({
        eventMap: this.eventMap,
        onInvalidFieldHandler: this.invalidFieldHandler,
        onValidFieldHandler: this.validFieldHandler
      });
      this.fnListGetters = {
        getCredentialTypeId: null,
        getLocation: "getRegion",
        getCredentialId: null,
        getRulesId: null,
        getCurrentLocation: null,
        getCurrentCredentialId: null,
        getSavedStep1Values: null,
        getSavedStep2ValueOverrides: null,
        getCurrentStep2Values: null,
        getCurrentPage: null,
      }
      this.fnListSetters = {
        updateSavedStep2ValueOverride: null,
        updateSavedStep1Value: null,
        onStep1Change: null
      }

      //create stub entries for all fn
      for (const fnList of [this.fnListGetters, this.fnListSetters]) {
        for (const fnIn of Object.keys(fnList)) {
          this[fnIn] = null;
        }
      }

      //init custom element getters, only if they're passed in
      if (args.dataGetters && typeof args.dataGetters === 'object') {
        for (const fnIn of Object.keys(this.fnListGetters)) {
          if (args.dataGetters[fnIn] && typeof args.dataGetters[fnIn] === 'function') {
            this[fnIn] = args.dataGetters[fnIn];
          }
        }
      }

      //init custom element setters, only if they're passed in
      if (args.dataSetters && typeof args.dataSetters === 'object') {
        for (const fnIn of Object.keys(this.fnListSetters)) {
          if (args.dataSetters[fnIn] && typeof args.dataSetters[fnIn] === 'function') {
            this[fnIn] = args.dataSetters[fnIn];
          }
        }
      }

      this.editModeOn = args.inEditMode;
      if (args.email && typeof args.email === 'string') {
        this.email = args.email;
      }
    }

    static getComparitorByField(fieldName) {
      return (a, b) => {
        if (a[fieldName].toUpperCase() < b[fieldName].toUpperCase()) {
          return -1;
        }
        if (a[fieldName].toUpperCase() > b[fieldName].toUpperCase()) {
          return 1;
        }
        return 0;
      };
    }

    getEmail() { return this.email; }

    getInlineValidationManager() { return this.inlineElementValidation; }

    getDDUXLogger() { return this.dduxLogger; }

    editModeEnabled() { return this.editModeOn; }

    /*
     *  DependentOptionManager
     */
    getDependentOptionManager() { return this.dependentOptionManager; }

    getDependentOptionQueryMap() { return this.getDependentOptionManager().getFinalizedMap(); }

    getInputSelectorMap() { return this.selectorMap; }

    getCustomElementPromiseArray() { return this.customElementUpdatePromises; }

    allCustomElementsAreUpdated() { return Promise.allSettled(this.customElementUpdatePromises); }

    disableDependentOptions() {
      this.getDependentOptionManager().disableDependentOptions();
    }

    /*
     * Event Listening
     */
    start() {
      this.startStopListeners("start");
      let optionEventLists = this.getDependentOptionManager().getDependentOptionEvents();
      if (optionEventLists && optionEventLists.startFunctions) {
        let startFuncs = optionEventLists.startFunctions;
        for (let fStart of startFuncs) {
          fStart();
        }
      }
    }

    stop() {
      this.startStopListeners("stop");
      let optionEventLists = this.getDependentOptionManager().getDependentOptionEvents();
      if (optionEventLists && optionEventLists.stopFunctions) {
        let stopFuncs = optionEventLists.stopFunctions;
        for (let fStop of stopFuncs) {
          fStop();
        }
      }
    }

    startStopListeners(whatToDo) {
      if (!whatToDo || ["stop", "start"].indexOf(whatToDo) < 0) {
        whatToDo = "stop";
      }
      for (let [selector, data] of this.eventMap.entries()) {
        let input = document.querySelector(selector);
        if (input) {
          if (whatToDo === "start") {
            if (data.oninput) { // radio/checkbox validate on change (input event)
              input.addEventListener("input", data.oninput, false);
            }
            input.addEventListener("blur", data.onblur, false);
            input.addEventListener("focus", data.onfocus, false);
          } else {
            if (data.oninput) { // radio/checkbox validate on change (input event)
              input.removeEventListener("input", data.oninput, false);
            }
            input.removeEventListener("blur", data.onblur, false);
            input.removeEventListener("focus", data.onfocus, false);
          }
        }
      }
    }

    /*
     * UI Rendering
     */
    elementIsEditable(elementInfo) {
      let isEditable = true;
      if (this.editModeEnabled()) {
        if (elementInfo && elementInfo.appendedData &&
          elementInfo.appendedData.currentStatus !== CloudStatus.Enum.TERMINATED &&
          !elementInfo.editableAfterStart) {
          isEditable = false;
        }
      }
      return isEditable;
    }

    getSectionTitles() {
      let sectionTitles = [];
      for (let section of this.uiSections) {
        sectionTitles.push(section.title);
      }
      return sectionTitles;
    }

    renderDetailsFromMap(valueMap, edit = false) {
      if (!valueMap || typeof valueMap.get !== 'function') {
        throw new TypeError("Invalid valueMap argument");
      }
      let detailsHtml = "";
      for (let section of this.uiSections) {
        let listItems = [];
        for (let uiElementInfo of section.elements) {
          if (uiElementInfo.id === "ConfirmPassword" && !edit) {
            continue; // skip if not rendering for edit.
          }
          let listItem = {
            label: uiElementInfo.label,
            value: _.escape(valueMap.get(uiElementInfo.id).displayValue),
            type: uiElementInfo.elementType
          };
          if (uiElementInfo.id.toLowerCase() === "accessprotocol") {
            let value = _.escape(valueMap.get(uiElementInfo.id).displayValue);
            if (uiElementInfo.allowedValues && typeof uiElementInfo.allowedValues === 'object') {
              value = uiElementInfo.allowedValues[value];
            }
            let label = Util.toTitleCase(uiElementInfo.label);
            listItem.label = label;
            listItem.value = value;
          }
          if (uiElementInfo.id === "VPC" && valueMap.get(uiElementInfo.id)) {
            let value = _.escape(valueMap.get(uiElementInfo.id).displayValue);
            let label = uiElementInfo.label;
            listItem.label = label;
            listItem.value = value;
          }
          if (uiElementInfo.id === "Subnet" && valueMap.get(uiElementInfo.id)) {
            let value = _.escape(valueMap.get(uiElementInfo.id).displayValue);
            let label = Util.toTitleCase(uiElementInfo.label);
            listItem.label = label;
            listItem.value = value;
          }
          if (uiElementInfo.id === "RootVolumeSize") {
            let value = listItem.value;
            let intValue = value;
            if (typeof value === 'string') {
              try {
                intValue = parseInt(value);
              } catch (error) {
                intValue = value;
              }
            }
            listItem.value = intValue;
          }
          listItems.push(listItem);
        }
        let html = DetailsSectionTemplate({
          sectionTitle: section.title,
          sectionExpanded: !!section.isExpanded,
          showOrHidePassword: I18NStringResource.showOrHidePassword,
          listItems: listItems
        });
        detailsHtml += html;
      }
      return detailsHtml;
    }

    renderDetails(values, edit = false) {
      let detailsHtml = "";
      for (let section of this.uiSections) {
        let listItems = [];
        for (let uiElementInfo of section.elements) {
          if (uiElementInfo.id === Util.getConfirmPasswordId(values) && !edit) {
            continue; // skip if not rendering for edit.
          }

          if (uiElementInfo.isCustomElement) {
            if (uiElementInfo.subElements && uiElementInfo.subElements.length) {
              if (uiElementInfo.customElementInfo.type === "autologin-licensemanager") {
                AutoLoginLicenseManager.UpdateDetailsPageValues(uiElementInfo, values);
              }
              for (const subElement of uiElementInfo.subElements) {
                const id = subElement.id;
                let label = subElement.attributes.label;
                if (!label && uiElementInfo.subElements.length === 1 && uiElementInfo.label) {
                  label = uiElementInfo.label;
                }
                const elementType = subElement.elementType;
                const listItem = {
                  label: label,
                  value: _.escape(values[id]),
                  type: elementType
                };
                listItems.push(listItem);
              }
            }
          } else { // standard element
            let value = uiElementInfo.defaultValue;
            if (uiElementInfo.id in values) {
              value = _.escape(values[uiElementInfo.id]);
            }
            const listItem = {
              label: uiElementInfo.label,
              value: value,
              type: uiElementInfo.elementType
            };
            if (uiElementInfo.id.toLowerCase() === "accessprotocol") {
              let value = _.escape(values.remote_access_options[values[uiElementInfo.id]]);
              let label = Util.toTitleCase(uiElementInfo.label);
              listItem.label = label;
              listItem.value = value;
            }
            if (uiElementInfo.id === "VPC" && values.vpcData) {
              let subListData = {};
              Util.copyProperties(values.vpcData, subListData, false, true, ["Subnets", "Tags"]);
              listItem.subListData = subListData;
            }
            if (uiElementInfo.id === "Subnet" && values.subnetData) {
              let subListData = {};
              Util.copyProperties(values.subnetData, subListData, false, true, ["Tags"]);
              listItem.subListData = subListData;
            }
            listItems.push(listItem);
          }
        }
        let html = DetailsSectionTemplate({
          sectionTitle: section.title,
          sectionExpanded: !!section.isExpanded,
          showOrHidePassword: I18NStringResource.showOrHidePassword,
          listItems: listItems
        });
        detailsHtml += html;
      }
      return detailsHtml;
    }

    render(section1FieldData, suppliedForm, values) {
      let form;
      if (suppliedForm) {
        form = suppliedForm;
      } else {
        form = this.createForm('section2Form');
      }
      let cloudLocation;
      if (section1FieldData && typeof section1FieldData === "object") {
        for (let section1Data of section1FieldData) {
          if (section1Data.id === "mw-cloud-location") {
            cloudLocation = section1Data.value;
            break;
          }
        }
      }
      let currentStatus = CloudStatus.Enum.UNKNOWN;
      if (this.editModeEnabled()) {
        if (values && values.cloudData && values.cloudData.state) {
          const statusData = {
            state: values.cloudData.state
          };
          currentStatus = CloudStatus.getStatus(statusData);
        }
      }
      for (let section of this.uiSections) {
        const sectionExpanded = !!section.isExpanded;
        const collapseSection = this.createSectionGroupBox(sectionExpanded);
        const groupBox = collapseSection.querySelector('fieldset.section2Group');
        for (let uiElementInfo of section.elements) {
          if (this.editModeEnabled()) {
            if (uiElementInfo.appendedData) {
              uiElementInfo.appendedData.currentStatus = currentStatus;
            } else {
              uiElementInfo.appendedData = {
                currentStatus: currentStatus
              };
            }
          }
          uiElementInfo.cloudLocation = cloudLocation;
          let currentValue = (values && values.hasOwnProperty(uiElementInfo.id)) ? values[uiElementInfo.id] : undefined;
          if (uiElementInfo.subElements && uiElementInfo.subElements.length && values) {
            const subElementValues = {};
            for (const subElement of uiElementInfo.subElements) {
              if (values.hasOwnProperty(subElement.attributes.id)) {
                const subVal = values[subElement.attributes.id];
                subElementValues[subElement.attributes.id] = subVal;
              }
            }
            currentValue = subElementValues;
          }
          // if editing existing resource, show current value, not proposed default
          if (this.editModeEnabled()) {
            uiElementInfo.defaultValue = currentValue;
          }
          this.appendInput(groupBox, uiElementInfo, currentValue);
        }

        const expando = this.createExpando(section.title, sectionExpanded);
        form.appendChild(expando);
        form.appendChild(collapseSection);
      }
      if (this.uiSections.length) {
        this.baseElement.appendChild(form);
      }
      if (section1FieldData && Array.isArray(section1FieldData)) {
        this.createHiddenFields(form, section1FieldData);
      }
      this.allCustomElementsAreUpdated().then(function () {
        this.getDependentOptionManager().finalize();
      }.bind(this));
      return this.baseElement;
    }

    createExpando(label, sectionExpanded = false) {
      const expando = document.createElement('div');
      expando.classList.add('additionalDetailHeader');
      expando.innerHTML = `<span class="horizontalIconContainer">
        <a class="sectionExpander">` +
        (sectionExpanded ? `<span class="icon-arrow-open-down">` : `<span class="icon-arrow-open-right">`) + `</span>
          <h2 class="subtitle">${label}</h2>
        </a>
      </span>`;
      return expando;
    }

    createForm(formName) {
      let form = document.createElement('form');
      form.setAttribute('name', formName);
      return form;
    }

    createSectionGroupBox(sectionExpanded) {
      const expando = document.createElement('div');
      expando.classList.add('sectionContents');
      if (!sectionExpanded) {
        expando.classList.add('collapsed');
      }
      const groupbox = document.createElement('fieldset');
      groupbox.classList.add('section2Group');
      expando.appendChild(groupbox);
      return expando;
    }

    createHiddenFields(form, hiddenFieldData) {
      if (form && hiddenFieldData && Array.isArray(hiddenFieldData) && hiddenFieldData.length) {
        for (let fieldData of hiddenFieldData) {
          if (this.getInlineValidationManager().validateHiddenFieldData(fieldData)) {
            let hiddenField = this.createHiddenInput(fieldData.id, fieldData.name, fieldData.value);
            form.appendChild(hiddenField);
            this.getInputSelectorMap().set(fieldData.id, {
              id: fieldData.id,
              selector: `input[type="hidden"]#${fieldData.id}`,
              label: "",
              required: false,
              hidden: true,
              secret: false
            });
          }
        }
      }
    }

    createHiddenInput(id, name, value) {
      let hiddenInput = document.createElement('input');
      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("name", name);
      hiddenInput.setAttribute("id", id);
      hiddenInput.value = value;
      return hiddenInput;
    }

    createInputElementContainer(elementInfo) {
      const inputContainer = document.createElement('div');
      inputContainer.classList.add('section2InputContainer');
      if (elementInfo.dataQueryType) {
        inputContainer.classList.add(elementInfo.dataQueryType);
      }
      if (elementInfo.groupTitle && typeof elementInfo.groupTitle === 'string') {
        let groupTitle = document.createElement('label');
        groupTitle.classList.add("groupTitle");
        groupTitle.textContent = elementInfo.groupTitle;
        inputContainer.appendChild(groupTitle);
      }
      if (elementInfo.elementType === 'radio' || elementInfo.elementType === 'checkbox') {
        inputContainer.classList.add("radioCheckbox");
      }
      return inputContainer;
    }

    setSubElementAttributes(customElement, elementInfo, currentValues) {
      if (elementInfo.subElements && elementInfo.subElements.length) {
        for (const subelement of elementInfo.subElements) {
          const attrBaseName = subelement.attributeBaseName;
          for (const attr of Object.entries(subelement.attributes)) {
            const attrName = `${attrBaseName}${attr[0]}`;
            const isPopText = (attrName === `${attrBaseName}poptext` && attr[1]);
            const attrVal = isPopText ? Util.processLinksInPopoverText(attr[1]) : attr[1];
            customElement.setAttribute(attrName, attrVal);
          }
          const valAttrName = `${attrBaseName}value`;
          if (currentValues && (currentValues.hasOwnProperty(subelement.id))) {
            customElement.setAttribute(valAttrName, currentValues[subelement.id]);
          } else {
            customElement.setAttribute(valAttrName, subelement.defaultValue);
          }
          if (subelement.elementType === 'checkbox' || subelement.elementType === 'radio') {
            const checkedAttrName = `${attrBaseName}checked`;
            const validValues = subelement.checkboxValues || [];
            let isChecked = false;
            const valueIndex = validValues.indexOf(customElement.getAttribute(valAttrName));
            if (valueIndex >= 0) {
              isChecked = (valueIndex === 1);
            }
            const checkedAttrValue = isChecked;
            // With custom elements, only set type Boolean attrs if true -- because even setting the attr with false, will end up being true.
            if (checkedAttrValue) {
              customElement.setAttribute(checkedAttrName, checkedAttrValue);
            }
            if (subelement.checkboxValues && subelement.checkboxValues.length === 2) {
              const checkboxValidValuesAttrName = `${attrBaseName}validvalues`;
              customElement.setAttribute(checkboxValidValuesAttrName, JSON.stringify(subelement.checkboxValues));
            }
          }
        }
      }
    }

    registerSubElements(elementInfo, customElement) {
      if (elementInfo.subElements && elementInfo.subElements.length && customElement) {
        // Wait for custom element's content (sub-elements) to become part of the DOM.
        // This promise is resolved when UI update (firstUpdated) is completed.
        // await customElement.updateComplete;
        let validator;
        if (elementInfo.subElements.length >= 1) {
          const customElementValidator = customElement.validate.bind(customElement);
          validator = customElementValidator;
        }
        for (const subelement of elementInfo.subElements) {
          const formSubmit = subelement.formSubmit;
          let elementType = subelement.elementType;
          const subelementid = subelement.attributes.id;
          const subelementlabel = subelement.attributes.label;
          let subElementSelector = "select";
          if (elementType === 'custom') {
            subElementSelector = "input"
            subelement.elementType = "input";
            elementType = "input"
          } else if (elementType === "select") {
            subElementSelector = elementType
          } else {
            subElementSelector = `input[type="${elementType}"]`
          }
          subElementSelector += `#${subelementid}`;

          if (subelement.elementType === 'select') {
            this.getCustomElementPromiseArray().push(customElement.updateComplete);
            customElement.updateComplete.then(function () {
              const selectElement = customElement.renderRoot.querySelector(subElementSelector);
              this.setupSelectOptionData(subelement, selectElement);
            }.bind(this));
          }

          if (formSubmit) {
            // Register sub element with form element map
            this.getInputSelectorMap().set(subelement.id, {
              id: subelementid,
              selector: subElementSelector,
              label: subelementlabel,
              required: !subelement.isOptional,
              hidden: false,
              secret: false
            });
          }
          // Setup inline validation, if needed
          if (subelement.validation) {
            this.getInlineValidationManager().createValidationEventMapEntry(subelement, validator, customElement);
          }
        }
      }
    }

    appendCustomInput(container, elementInfo, currentValues) {
      let inputContainer = null;
      if (container && elementInfo && elementInfo.isCustomElement) {
        const customElementInfo = elementInfo.customElementInfo;
        const customElementTag = customElementInfo.type;
        try {
          // Create input element's container
          inputContainer = this.createInputElementContainer(elementInfo);
          // Create custom element
          const customElement = document.createElement(customElementTag);
          // Set attributes
          customElement.setAttribute('id', elementInfo.id);
          customElement.setAttribute('elementlabel', elementInfo.label);
          customElement.setAttribute('poptext', Util.processLinksInPopoverText(elementInfo.finePrint));
          customElement.setAttribute('placeholder', elementInfo.prompt);
          customElement.setAttribute('value', elementInfo.defaultValue);
          customElement.setAttribute('dduxenabled', true);

          for (const fnList of [this.fnListGetters, this.fnListSetters]) {
            for (let [fnIn, fnOut] of Object.entries(fnList)) {
              if (!fnOut) {
                fnOut = fnIn;
              }

              const fn = this[fnIn];
              if (fn && typeof fn === 'function') {
                customElement[fnOut] = fn;
              }
            }
          }

          // Set component's sub-elements' attributes, if any
          this.setSubElementAttributes(customElement, elementInfo, currentValues);
          if (this.editModeEnabled() && !this.elementIsEditable(elementInfo)) {
            customElement.disabled = true;
            customElement.classList.add('disabled');
          }
          // Add custom element to input container
          inputContainer.appendChild(customElement);
          // Add input container to main container
          container.appendChild(inputContainer);
          // Register main input element with form element map
          this.registerSubElements(elementInfo, customElement);
          // Setup inline validation, if needed
          if (elementInfo.validation) {
            this.getInlineValidationManager().setupInlineInputValidation(elementInfo, inputContainer, customElement.validate.bind(customElement));
          }
        } catch (error) {
          Util.consoleLogError(`appendCustomInput with custom tag ${customElementTag}`, error);
          inputContainer = null;
        }
      }
      return inputContainer;
    }

    createLabelElement(elementInfo) {
      const label = document.createElement('label');
      label.setAttribute('for', elementInfo.id);
      if (elementInfo.elementType === 'radio' || elementInfo.elementType === 'checkbox') {
        label.textContent = elementInfo.label;
        label.classList.add("radioCheckboxLabel");
      } else {
        label.textContent = elementInfo.isOptional ? elementInfo.label + " " + I18NStringResource.optional : elementInfo.label;
      }
      return label;
    }

    appendExternalLinksToLabel(label, elementInfo) {
      if (elementInfo.dataQueryType === "aws_ec2_keypair" && elementInfo.cloudLocation) {
        let externalLinkContainer = document.createElement('div');
        let leftParen = document.createElement('span');
        let rightParen = document.createElement('span');
        let externalLink = document.createElement('a');
        externalLink.setAttribute("id", "createNewKeyPair");
        externalLink.setAttribute("tabindex", "0");
        externalLink.dataset.href = "#";
        externalLink.innerText = I18NStringResource.wizardCreateAWSKeyPair;
        const logDDUX = function () { Util.logDDUXinfoFromClickEvent(event, this.getDDUXLogger()); }.bind(this);
        externalLink.addEventListener("click", logDDUX);
        let credID;
        let clickFn;
        let selectedCred = document.querySelector("select#credentialSelector");
        if (selectedCred && selectedCred.selectedOptions && selectedCred.selectedOptions.length) {
          let values = selectedCred.selectedOptions[0].value.split(":");
          if (values && values.length > 3) {
            //aws:credTypeID:credID:label
            credID = values[2];
          }
        }

        if (credID) {
          clickFn = this.openAWSKeyPairPrompt.bind(this);
          externalLink.addEventListener("keyup", function (event) {
            if (event && /^(13|32)$/.test(event.which)) {
              clickFn(elementInfo.cloudLocation, elementInfo.id, credID);
            }
            return false;
          });
          externalLink.addEventListener("click", function (event) {
            clickFn(elementInfo.cloudLocation, elementInfo.id, credID);
          });

        } else {
          clickFn = function () {
            Util.notify("ERROR", I18NStringResource.wizardCreateAWSKeyPairErrorInit);
          }.bind(this);
        }

        leftParen.innerText = " (";
        rightParen.innerText = ")";

        externalLinkContainer.appendChild(leftParen);
        externalLinkContainer.appendChild(externalLink);
        externalLinkContainer.appendChild(rightParen);

        label.appendChild(externalLinkContainer);
      }
    }

    appendStandardInput(container, elementInfo, currentValue) {
      // Create input element's container
      const inputContainer = this.createInputElementContainer(elementInfo);

      // Create label with possible external links and append to input container
      const label = this.createLabelElement(elementInfo);
      this.appendExternalLinksToLabel(label, elementInfo);
      inputContainer.appendChild(label);

      // Create section2InputContainer wrapper to hold just the input and its inline-validation only.
      // This enables the inline-validation to work consistently across elements.
      const validationWrapper = document.createElement('div');
      validationWrapper.classList.add('section2InputValidationContainer');  // important: validation looks for this class.
      inputContainer.appendChild(validationWrapper);

      // Create standard HTML input element
      let input = this.createInputElement(elementInfo, currentValue);
      if (input) {
        // Add input element to input container
        if (input.type === "password") {
          const passwordInputContainer = document.createElement('div');
          passwordInputContainer.classList.add("passwordInputContainer");
          passwordInputContainer.appendChild(input);
          this.appendViewPasswordButton(passwordInputContainer);
          validationWrapper.appendChild(passwordInputContainer);
        } else {
          validationWrapper.appendChild(input);
        }

        if (elementInfo.elementType !== 'radio' && elementInfo.elementType !== 'checkbox') {
          // Setup inline validation, if needed
          if (elementInfo.validation) {
            this.getInlineValidationManager().setupInlineInputValidation(elementInfo, validationWrapper);
          }
        } else {
          // always validate as true b/c cannot change, but need validation to occur to trigger code that enables save button
          // b/c w/o this fn, that code doesn't run
          this.getInlineValidationManager().setupInlineInputValidation(elementInfo, validationWrapper, () => true);
        }
      }
      if (this.editModeEnabled() && !this.elementIsEditable(elementInfo)) {
        input.disabled = true;
        input.classList.add('disabled');
      }

      // Add input container to main container
      container.appendChild(inputContainer);

      // Add popover element
      if (elementInfo.finePrint) {
        let detailsButton = this.createDetailInfoButton(elementInfo.finePrint);
        inputContainer.appendChild(detailsButton);
      }

      return inputContainer;
    }

    appendInput(container, elementInfo, currentValue) {
      let inputContainer = null;

      if (elementInfo.elementType === 'hidden') {
        const input = this.createInputElement(elementInfo, currentValue);
        container.appendChild(input);
      } else if (elementInfo.isCustomElement) {
        inputContainer = this.appendCustomInput(container, elementInfo, currentValue);
      } else {
        inputContainer = this.appendStandardInput(container, elementInfo, currentValue);
      }
      return inputContainer;
    }

    openAWSKeyPairPrompt(region, paramID, credID) {
      const existingValues = [];
      const defaultValue = this.getEmail() ? this.getEmail() : ""

      const inputElement = document.querySelector(`#${paramID}`);
      if (inputElement) {
        for (const opt of inputElement.options) {
          existingValues.push(opt.value);
        }
      }

      const maxLen = 50;
      const uniqueDefaultValue = Util.makeValueUnique(defaultValue, existingValues, maxLen)


      const args = {
        dialogTitle: I18NStringResource.wizardCreateAWSKeyPairDialogTitle,
        explanation: I18NStringResource.wizardCreateAWSKeyPairDialogExplanation,
        actionButtonLabel: I18NStringResource.wizardCreateAWSKeyPairConfirmButtonLabel,
        cancelButtonLabel: I18NStringResource.wizardCreateAWSKeyPairCancelButtonLabel,
        actionFn: this.createAWSKeyPair.bind(this),
        className: "AWSKeyPairPrompt",
        dduxLogger: this.getDDUXLogger(),
        hiddenArray: [
          { id: "region", value: region },
          { id: "paramID", value: paramID },
          { id: "credID", value: credID },
        ],
        inputArray: [{
          label: I18NStringResource.wizardCreateAWSKeyPairDescriptionInput,
          id: `keyNameInput`,
          type: "input", value: uniqueDefaultValue, maxlength: maxLen
        }]
      };
      this.showAWSKeyPairDialog(args);
    }

    showAWSKeyPairDialog(args) { // stubbing show for testing openAWSKeyPairPrompt()
      const inputPromptDialog = new InputPromptDialog(args);
      inputPromptDialog.show();
    }

    async createAWSKeyPair(values) {
      if (!values || typeof values !== "object") {
        return;
      }
      let credID = _.unescape(values.get('credID'));
      let region = _.unescape(values.get('region'));
      let paramID = _.unescape(values.get('paramID'));
      let desc = _.unescape(values.get('keyNameInput'));

      if (!desc || desc.trim() === "") {
        throw I18NStringResource.wizardCreateAWSKeyPairErrorRequired;
      }

      const descText = desc.trim();
      if (descText.length > 50) {
        throw I18NStringResource.wizardCreateAWSKeyPairErrorTooLong;
      }

      let inputElement = document.querySelector(`#${paramID}`);
      if (inputElement) {
        for (const opt of inputElement.options) {
          if (opt.value === descText) {
            throw I18NStringResource.wizardCreateAWSKeyPairErrorAlreadyExists;
          }
        }

        //downloads a file, so not using DAO
        const baseURL = Config.getMicroServiceURL();
        let url = `${baseURL}/awsec2/resource/${credID}/sshkey?name=${encodeURIComponent(descText)}&region=${encodeURIComponent(region)}`;
        try {
          const response = await fetch(
            url,
            {
              method: 'post',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/x-pem-file'
              }
            }
          );
          if (!response.ok) {
            throw new Error(`HTTP: ${response.status} ${response.statusText}`);
          }
          const data = await response.blob();
          if (data) {
            url = LandingPageNavButtons.stringToURL(data, "application/x-pem-file");
            const a = document.createElement("a");
            a.href = url;
            a.style.display = 'none';
            a.download = `${descText}_${credID.substring(0, 6)}_${region}.pem`;
            a.type = "application/x-pem-file";
            a.target = "_self";
            a.click();
          }
        } catch (error) {
          Util.consoleLogError("createAWSKeyPair", error);
          Util.notify("WARNING", DojoString.substitute(I18NStringResource.wizardCreateAWSKeyPairDownloadError, [error.message]));
        }

        let option = new Option(descText, descText);
        option.selected = true;
        inputElement.add(option, undefined);
        inputElement.dispatchEvent(new Event("change"));

      }

    }

    createDetailInfoButton(finePrint) {
      let button;
      if (finePrint && typeof finePrint === 'string') {
        button = document.createElement('inline-popover');
        button.setAttribute("poptext", Util.processLinksInPopoverText(finePrint));
      }
      return button;
    }

    appendViewPasswordButton(container) {
      let button = document.createElement('button');
      button.classList.add("btn", "btn_color_blue", "companion_btn", "viewPassword");
      button.type = "button";
      button.title = I18NStringResource.showOrHidePassword;
      let srOnlySpan = document.createElement('span');
      srOnlySpan.classList.add("visually-hidden");
      srOnlySpan.textContent = I18NStringResource.showOrHidePassword;
      button.appendChild(srOnlySpan);
      let icon = document.createElement('div');
      icon.classList.add("viewPasswordIcon", "smallIcon");
      button.appendChild(icon);
      container.appendChild(button);
    }

    appendRow(container, id, labelText, value, isObject) {
      let row = document.createElement('div');
      row.style.display = 'table-row';
      let label = document.createElement('label');
      label.setAttribute('for', id);
      label.textContent = labelText;
      let cell = document.createElement('div');
      cell.setAttribute('id', id);
      cell.style.display = 'table-cell';
      if (isObject) {
        cell.appendChild(value);
      } else {
        cell.textContent = value;
      }
      row.appendChild(label);
      row.appendChild(cell);
      container.appendChild(row);
      return row;
    }

    setupSelectOptionData(elementInfo, selectElement) {
      if (elementInfo.elementType === 'select') {
        if (elementInfo.dataQueryType) {
          this.getDependentOptionManager().add(elementInfo);
        } else if (elementInfo.settingsIncludeKey && selectElement) {
          let hasIncludeData = (Boolean(elementInfo.settingsIncludeKey) &&
            this.settingsMap.has(elementInfo.settingsIncludeKey));
          if (hasIncludeData) {
            let includeData = this.settingsMap.get(elementInfo.settingsIncludeKey);
            let hasExcludeData = (Boolean(elementInfo.settingsExcludeKey) &&
              this.settingsMap.has(elementInfo.settingsExcludeKey));
            let excludeData;
            if (hasExcludeData) {
              excludeData = new Map();
              for (const optionData of this.settingsMap.get(elementInfo.settingsExcludeKey)) {
                excludeData[optionData.value] = optionData.text;
              }
            }

            // special case for ClusterLogLevel
            if (elementInfo.id === 'ClusterLogLevel') {
              if (includeData) {
                includeData = includeData.sort(UIGenerator.getComparitorByField('text'));
              }
            }
            for (const optionData of includeData) {
              if (hasExcludeData && excludeData[optionData.value]) {
                continue;
              }
              let option = new Option(optionData.text, optionData.value);
              if (elementInfo.defaultValue && (optionData.value === elementInfo.defaultValue)) {
                option.selected = true;
              }
              selectElement.add(option, undefined);
            }
          }
        }
      }
    }

    createInputElement(elementInfo, currentValue) {
      if (this.editModeEnabled() && !this.elementIsEditable(elementInfo)) {
        if (elementInfo.elementType === 'select') {
          elementInfo.elementType = 'text';
        }
      }
      let type = elementInfo.elementType;
      let inputElement;
      switch (type) {
        case 'text':
          inputElement = document.createElement('input');
          inputElement.setAttribute("type", "text");
          if (currentValue) {
            inputElement.value = currentValue;
          } else if (elementInfo.defaultValue) {
            inputElement.value = elementInfo.defaultValue;
          } else if (elementInfo.prompt) {
            inputElement.setAttribute("placeholder", elementInfo.prompt);
          }
          this.getInputSelectorMap().set(elementInfo.id, {
            id: elementInfo.id,
            selector: `input[type="text"]#${elementInfo.id}`,
            label: elementInfo.label,
            required: !elementInfo.isOptional,
            hidden: false,
            secret: false
          });
          break;
        case 'hidden':
          inputElement = document.createElement('input');
          inputElement.setAttribute("type", "hidden");
          if (currentValue) {
            inputElement.value = currentValue;
          } else if (elementInfo.defaultValue) {
            inputElement.value = elementInfo.defaultValue;
          }
          this.getInputSelectorMap().set(elementInfo.id, {
            id: elementInfo.id,
            selector: `input[type="hidden"]#${elementInfo.id}`,
            label: "",
            required: !elementInfo.isOptional,
            hidden: true,
            secret: false
          });
          break;
        case 'password':
          inputElement = document.createElement('input');
          inputElement.setAttribute("type", "password");
          inputElement.setAttribute("autocomplete", "off");
          inputElement.classList.add("password");
          if (currentValue) {
            inputElement.value = currentValue;
          } else if (elementInfo.defaultValue) {
            inputElement.value = elementInfo.defaultValue;
          }
          this.getInputSelectorMap().set(elementInfo.id, {
            id: elementInfo.id,
            selector: `input.password#${elementInfo.id}`,
            label: elementInfo.label,
            required: !elementInfo.isOptional,
            hidden: false,
            secret: true
          });
          break;
        case 'number':
          inputElement = document.createElement('input');
          inputElement.setAttribute("type", "number");
          if (currentValue) {
            inputElement.value = currentValue;
          }
          if (elementInfo.validation && elementInfo.validation.range) {
            let range = elementInfo.validation.range;
            if (range.min && !isNaN(range.min)) {
              inputElement.setAttribute("min", range.min);
            }
            if (range.max && !isNaN(range.max)) {
              inputElement.setAttribute("max", range.max);
            }
          }
          if (elementInfo.defaultValue) {
            inputElement.value = elementInfo.defaultValue;
          }
          this.getInputSelectorMap().set(elementInfo.id, {
            id: elementInfo.id,
            selector: `input[type="number"]#${elementInfo.id}`,
            label: elementInfo.label,
            required: !elementInfo.isOptional,
            hidden: false,
            secret: false
          });
          break;
        case 'textarea':
          inputElement = document.createElement('textarea');
          if (elementInfo.visibleLines) {
            inputElement.setAttribute("rows", elementInfo.visibleLines);
          }
          if (currentValue) {
            inputElement.value = currentValue;
          } else if (elementInfo.defaultValue) {
            inputElement.value = elementInfo.defaultValue;
          } else if (elementInfo.prompt) {
            inputElement.setAttribute("placeholder", elementInfo.prompt);
          }
          this.getInputSelectorMap().set(elementInfo.id, {
            id: elementInfo.id,
            selector: `textarea#${elementInfo.id}`,
            label: elementInfo.label,
            required: !elementInfo.isOptional,
            hidden: false,
            secret: false
          });
          break;
        case 'select':
          inputElement = document.createElement('select');
          if (elementInfo.visibleLines) {
            inputElement.setAttribute("size", elementInfo.visibleLines);
          }
          if (elementInfo.selection && elementInfo.selection === 'multiple') {
            inputElement.setAttribute("multiple", "true");
          }
          if (elementInfo.prompt) {
            let promptOption = new Option(elementInfo.prompt, "");
            promptOption.disabled = true;
            promptOption.hidden = true;
            promptOption.selected = true;
            inputElement.add(promptOption);
          }
          this.setupSelectOptionData(elementInfo, inputElement);

          this.getInputSelectorMap().set(elementInfo.id, {
            id: elementInfo.id,
            selector: `select#${elementInfo.id}`,
            label: elementInfo.label,
            required: !elementInfo.isOptional,
            hidden: false,
            secret: false
          });
          break;
        case 'radio':
          // create surronding fieldset
          const fieldset = document.createElement('fieldset');
          fieldset.classList.add("radioCheckboxFieldset");
          if (elementInfo.settingsIncludeKey) {
            const hasIncludeData = (Boolean(elementInfo.settingsIncludeKey) &&
              this.settingsMap.has(elementInfo.settingsIncludeKey));
            if (hasIncludeData) {
              let includeData = this.settingsMap.get(elementInfo.settingsIncludeKey);
              const hasExcludeData = (Boolean(elementInfo.settingsExcludeKey) &&
                this.settingsMap.has(elementInfo.settingsExcludeKey));
              let excludeData;
              if (hasExcludeData) {
                excludeData = new Map();
                for (const optionData of this.settingsMap.get(elementInfo.settingsExcludeKey)) {
                  excludeData[optionData.value] = optionData.text;
                }
              }

              for (const optionData of includeData) {
                if (hasExcludeData && excludeData[optionData.value]) {
                  continue;
                }
                // create initial radio button
                const radioDiv = document.createElement('div');
                radioDiv.classList.add('radioBtnContainer');
                const radioButton = document.createElement('input');
                radioButton.type = 'radio';
                radioButton.name = elementInfo.name;
                radioButton.value = optionData.value;
                radioButton.id = `${elementInfo.name}_${optionData.value.replaceAll(" ", "_")}`
                if (elementInfo.defaultValue && (optionData.value === elementInfo.defaultValue)) {
                  radioButton.checked = true;
                }
                radioDiv.appendChild(radioButton);
                const label = document.createElement('label');
                label.for = optionData.value;
                label.textContent = optionData.text;
                label.classList.add("radioCheckboxLabel");
                radioDiv.appendChild(label);
                fieldset.appendChild(radioDiv);
              }
            }
          }

          this.getInputSelectorMap().set(elementInfo.id, {
            id: elementInfo.id,
            selector: `input[type="radio"][name="${elementInfo.name}"]:checked`,
            label: elementInfo.label,
            required: !elementInfo.isOptional,
            hidden: false,
            secret: false
          });
          inputElement = fieldset;
          break;
        case 'checkbox':
          inputElement = document.createElement('input');
          inputElement.setAttribute("type", "checkbox");
          const value = (currentValue ? currentValue : (elementInfo.defaultValue ? elementInfo.defaultValue : undefined));
          let isChecked = false;
          let isYesNoCheckbox = false;
          if (elementInfo.checkboxValues.length === 2 && elementInfo.checkboxValues.includes(value)) {
            isYesNoCheckbox = true;
            isChecked = (elementInfo.checkboxValues.indexOf(value) === 1);
          } else {
            isChecked = (currentValue ? (currentValue.toLowerCase() === "true") : elementInfo.checked);
          }
          inputElement.checked = isChecked;
          if (isYesNoCheckbox) {
            inputElement.dataset["checkboxtype"] = "yesno";
            inputElement.value = value;
            inputElement.onchange = (event) => {
              const validValues = elementInfo.checkboxValues;
              const checkbox = event.target;
              if (checkbox.checked) {
                checkbox.value = validValues[1];
              } else {
                checkbox.value = validValues[0];
              }
            };
          } else {
            inputElement.dataset["checkboxtype"] = "truefalse";
            inputElement.value = Boolean(isChecked).toString();
            inputElement.onchange = (event) => {
              const checkbox = event.target;
              checkbox.value = checkbox.checked.toString();
            };
          }
          this.getInputSelectorMap().set(elementInfo.id, {
            id: elementInfo.id,
            selector: `input[type="checkbox"]#${elementInfo.id}`,
            label: elementInfo.label,
            required: !elementInfo.isOptional,
            hidden: false,
            secret: false
          });
          break;
        default:
          throw new TypeError(`Invalid elementType value: \"${type}\"`);
      }
      inputElement.setAttribute("id", elementInfo.id);
      // fieldsets contain radio or checkbox groups that use name
      // so, don't set name on the fieldset element
      if (inputElement.tagName.toUpperCase() !== 'FIELDSET') {
        inputElement.setAttribute("name", elementInfo.name);
      }
      if (elementInfo.disabled) {
        inputElement.disabled = true;
        inputElement.classList.add('disabled');
      }
      return inputElement;
    }

  }

  return UIGenerator;
});
